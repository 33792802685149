
.view.collapsed-tiles {
	.categories-sub-title {
		cursor: pointer;
	
		span.icon {
			width: 24px;
			height: 24px;
			display: inline-block;
			background: {
				size: 14px;
				position: center;
				image: url(../images/icon/sort-right-60.png);
				repeat: no-repeat;
			}
			transform: rotate(90deg);
			opacity: 0.5;
			transition: .4s ease;
		}
	}
}
.view.collapsed-tiles.collapsed {
	.categories-sub-title {
		span.icon {
			transform: none;
			transition: .3s ease;
		}
	}
}