/* ==== general styles - Hallowell Theme - BLUESTEEL ==== */
@import "bsColors";
@import "collapsed-tiles-view";

/*** vars ***/
:root {
	--prcnt: 0%;
	--prcntFl: 100%;
}
/**** --**&& ### general CSS ### &&**-- ****/
.layout-editor-block-title.clearfix .text {
    display: none;
}

body {
	overflow-x: hidden;
	background: {
		image: url(/files/inline-images/subtle_grunge.png);
		repeat: repeat;
		position: center;
		attachment: fixed;
	}
}

/*############## ++++ Cookies Consent ++++ ######## Mobile First ############*/
div#tarteaucitronIcon {
    display: none;
    opacity: 0;
	height:0;
	width:0;
	overflow:  hidden;
}

// show on privacy policy page
.page-node-3340 div#tarteaucitronIcon {
    display: block;
	height:auto;
	width:auto;
    opacity: 1;
}
#tarteaucitron a.tarteaucitronSelfLink {
    display: none;
}
#tarteaucitronIcon #tarteaucitronManager {
    background-color: #ffffff45;
    border-radius: 100px;
    padding: 7px 7px 5px 5px;
    margin: 10px;
	transition: 300ms ease;

	img {
		padding: 2px;
	}

	&:hover {
		background-color: #fff;
	}
}

div.block-views-page-block-items-general-block-2 {
	position: fixed;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
  //background-color: #2b2b2eb0;
	opacity: 0;
	z-index: 100;
	transition: 0.3s ease;
	
	&.deleteMe {
		transition: 0.4s ease;
		background-color: transparent;

		.consent-cookie-msg,
		&.shoMe .consent-cookie-msg {
			transition: 0.4s ease;
			transform: translate(0, 150px);
			opacity: 0;
		}
	}

	.block-content {
		display: flex;
		height: 100%;
		width: 100%;
		flex-wrap: wrap;
		align-content: flex-end;
		transition: 0.4s ease;
		pointer-events: none;
	}
	.consent-cookie-msg {
		background-color: #dee2ea;
		background-color: #dee2ea7d;
		border-radius: 8px 8px 0 0;
		margin: auto;
		width: 600px;
		max-width: 80%;
		padding: 1em;
		box-shadow: 2px 2px 12px -4px #525e64;
		transform: translate(0, 150px);
		transition: 0.5s ease;

		&:hover {
			backdrop-filter: none;
		}

		>.view-content {
			max-width: 100%;
		}

		.view-content .views-row.views-row-1 {
			position: relative;
			margin: auto;
			width: 100%;
			display: grid;
			grid-template-columns: 1fr 4fr 5fr;
			grid-template-areas:'img ttl ttl' 
								'txt txt txt'
								'acc acc out';
			justify-content: center;
			align-items: center;
			border: 2px solid $iceGray;
			background-color: $drab;
			padding: 12px;
		}

		.views-field-field-image {
			grid-area: img;
			height: 100%;
			
			.cookie-img {
				opacity: 1;
				padding: 12px;
				border-radius: 100px;
				overflow: hidden;
				position: absolute;
				max-height: 85px;
				width: auto;
				height: 100%;
				top: -48px;
				left: -38px;
				background-color: #FFF;
				border: 2px solid $iceGray;

				img {
					margin: auto;
					max-height: 100%;
					height: auto;
					width: auto;
				}
			}
		}

		// cookie message text
		.views-field-field-text-line-1 {
			grid-area: txt;
			
			p {
				margin: 5px auto 0;
				padding-left: 3px;
				color: $ice;
				line-height: 1.3;
				font-weight: 200;
   				letter-spacing: 0.7px;
				text-shadow: 1px 1px 1px #000;
				
				a {
					color: $sage;

					&:hover {
						color: $linkBlue;
					}
					&:active {
						color: $ice;
					}
				}
			}
		}

		// Cookie Title
		.views-field-field-text-line-2 {
			padding-right: 12px;
			grid-area: ttl;
			text-align: left;

			h3.cookieMsg-title {
				font-weight: 600;
				color: $steelBluegreen;
				border-bottom: 4px solid $steelBlue;
				margin-bottom: 3px;
				padding-bottom: 4px;
				text-align: left;
				font-size: 22px;
				line-height: 1;
				padding-left: 8px;
			}
		}

		// Cookie Buttons
		.views-field-field-button-link,
		.views-field-field-text-line-3 {
			margin-top: 12px;

			.c-button {
				a {
					display: block;
					cursor: pointer;
					color: $sage;
					border: 2px solid #3f9797;
					padding: 10px 15px;
					background-color: #0073bd;
					text: {
							shadow: 0px 0px 2px #2e48eb;
							align: center;
					}
					transition: 0.3s ease;

					&:active,
					&:hover {
						background-color: #2392ff;
						color: #fdffa4;
						text-shadow: 0px 0px 5px #ffffffa3;
						border-color: $sage;
					}
				}
			}
		}

		// Cookie Accept Button
		.views-field-field-button-link {
			grid-area: acc;

			.c-button {
				padding-right:3px;
			}
		}

		// Cookie Decline Button
		.views-field-field-text-line-3 {
			grid-area: out;

			.c-button {
				padding-left:3px;

				#optOutCooki {
					&:active,
					&:hover {
						color: #f89b65;
						text-shadow: 0px 0px 5px #faece8a3;
						border-color: $rust;
						background-color: $onyx;
					}
				}
			}
		}
	}
	
	&.shoMe {
		pointer-events: none;
		opacity: 1;
		transition: 320ms ease 590ms;
		
		.consent-cookie-msg {
			opacity: 1;
			backdrop-filter: blur(5px);
			transform: translate(0, 5px);
			transition: 560ms ease-out 580ms;
			pointer-events: all;

			&:hover {
				backdrop-filter: none;
			}
		}
	}
}
/*####---END---#### ---Cookies-Consent--- ###Mobile####END#########*/

/***** SKU & ADDON - page display ******/
body.node-type-sku,
body.node-type-addon {
	a {
		color: $linkBlue;
	}
	a:hover,
	a:active {
		color: #0195fd;
	}
	#linkbox a {display: block;}
	article.view-mode-full.node-sku,
	article.view-mode-full.node-addon {
		background-color: #FFF;
		padding: 2em 3em;
		margin-top: 1em;
		border-radius: 12px;
		box-shadow: 5px 5px 17px -8px #29364099;
		
		.field-label-inline {
			.field-label {
				color: #6b787b;
				font-weight: 500;
				text-transform: capitalize;
				padding-right: 0.3em;
			}
			.field-items .field-item {
				font-size: 1.2em;
				line-height: 1.3;
			}
		}
		.field-name-field-table-name.field-type-text {
			font-size: 1.3em;
			color: $steelBlue;
		}
		.field-name-field-tag {
			.field-items .field-item {
			    color: $onyx;
				font-weight: 500;
				font-family: $subFont;
				letter-spacing: 0.2px;
			}
		}
		.field.field-name-field-hero-image.field-type-image {
			img {
				border: 2px solid transparent;
				border-radius: 4px;
				box-shadow: 2px 2px 24px -8px transparent;
				transition: 450ms ease;
			}
			img:hover {
				border-color: $steelBluegreen;
				box-shadow: 2px 2px 24px -8px $shadow_A;
				transition: border-color 750ms ease, box-shadow 1500ms ease;
			}
		}
	}
}
/***** SKU -  page display ******/
body.node-type-sku {
	.block.block-views-sku-collection-block-9, 
	.block.block-views-sku-collection-block-8 {
		margin-left: 2em;
			
	}
}
/***** ADDON -  page display ******/
body.node-type-addon {
	.block.block-views-link-components-to-pages-block-13 {
		padding: 0.2em 3em 1em;
		
		h2.block-title {
			font-size: 24px;
			color:  $steelBluegreen
		}
	}
	article.view-mode-full.node-addon {	
		>.content.clearfix {
			display: flex;
			flex-wrap: wrap;
			column-gap: 12px;
			
			.field-name-field-subtitle.field-type-text {
				width: 100%;
				flex: 0 0 100%;
				
				
				.field-items {
					.field-item {
						font: {
							family: $subFont;
							size: 1.85em;
							weight: 800;
						}
					}
				}
			}
			.group-left-column {
				flex: 2 1 30%;
			}
			.group-right-column {
				flex: 1 2 20%;
				min-width: 420px;
			}
		}
		.field.field-name-field-tag.field-type-taxonomy-term-reference {
			height: 1em;
			
			.field-items {
				display: none;
				
				.field-item {
					font-size: 15px;
					color: #74757b;
					text-transform: uppercase;
				}
			}
		}
	}
}
/** general content pages --Mobile-First-- **/
.node--updated-date {
	.views-row.first {
		margin: 1.2em;
	}

	.views-field.views-field-changed {
		display: inline;
		font-size: 1.2em;
		color: $steelBluegreen;
		padding: 8px 14px;
		border: 6px solid;
		border-width: 4px 0;

		.field-content.policy-date {
			display: inline;
			color: $steelBlue;
		}
	}
}

article.node-page {
	.content.container {
		p.note {
			margin-left: 1%;
			padding-left: 1em;
		}

		.top-section.cont-sect-0.section {
			padding: 0 18px;
			p {
				font-size: medium;
			}
		}
		.section-container {
			.itemTitle {
				padding-bottom: 16px;
			}
			>h1,
			>h2 {
				border-radius: 5px;
				background-image: linear-gradient(to right, #f2f3f4ba, transparent);
			}
			>h1 {
				margin-top: 32px;
				padding-left: 6px;
				color: $steelBlueGray;
				text-shadow: 1px 4px 0px #dee4e8;
			}
			>h2 {
				margin-top: 16px;
				padding: 3px 18px 2px;
				text-shadow: 
					0 0 12px #fff,
					1px 1px 5px $ice,
					-1px -1px 4px #FFF,
					2px 2px 2px $lightIce;
				color: $drab;
			}
			>p {
				padding-left: 18px;
				margin: {
					top: 0;
					bottom: 0;
				}
			}
			.content-container {
				background-color: #FFF;
				border-radius: 8px;
				padding: 14px 18px;
				box-shadow: 2px 2px 12px -2px $shadow_A;
				margin: {
					top: 8px;
					bottom: 1.2em;
				}

				.sub-section {
					padding: 4px 18px;
					border-bottom: 6px solid $iceGray;
					margin: 8px 1% 20px;

					&:last-child {
						border-bottom: unset;
					}

					&.flex-container {
						display: flex;
						flex-wrap: wrap;
						
						.color-block {
							flex: 1 1 25%;
						}
						
						p.note,
						h4 {
							width: 100%;
							flex: 0 0 100%;
						}
					}

					h5 {
						font: {
							family: $smallFont;
							size: 16px;
							weight: 400;
						}
						color: $steelBluegreen;
					}
				}
			}
			#stock-prods-section {
				.items-container {
					display: flex;
					flex-wrap: wrap;

					.this-item {
						display: flex;
						flex-wrap: no-wrap;
						flex: 1 1 50%;
						
						.item-name {
							flex: 1 1 50%;

						}
						.item-codes {
							flex: 1 1 40%;
						}
					}
				}
			}
		}
	}
}

/******** Page ** Mobile First ***********/

body.node-type-page {

	/********* Side Nav *** Mobile First ***********/
	.side-nav-container {
		position: fixed;
		top: 0;
		left: 0;
		margin: 86px 15px 12px 2%;
		padding: 10px 4px;
		text-align: center;
		max-width: 96vw;
		height: 48px;
		width: 48px;
		color: $onyx;
		background-color: #FFF;
		border: 2px solid $sage;
		border-radius: 100px;
		box-shadow: 1px 1px 10px -2px $lightIce;
		box-sizing: border-box;
		overflow: hidden;
		transform: translate(-235px, 0);
		transition: 0.3s ease;	
		opacity: 0;
		
		nav.policy-nav {
			padding: 1em;
			box-shadow: 0 2px 16px transparent;
			transform: translate(-400px, -150px);
			scale: 0.2;
			opacity: 0;
			transition: 0.5s ease 0.1s, scale 0.3s ease 0s;

			ul.top-level-parent {
				padding: {
					top: 8px;
					left: 0;
				}

				li.nav-item {
					list-style: none;
					padding: 0 4px;
					border-bottom: 1px solid #ececec;
					margin-bottom: 0;

					&:last-child {
						border-bottom: none;
					}

					a.nav-btn {
						display: block;
						padding: 4px 12px;
						color: $steelBluegreen;
						text-align: center;			
					}
				}
			}
		}

		&.inZone {
			transform: translate(0, 0);
			opacity: 1;
		}

		// &:hover,
		&.active.inZone {
			height: 302px;
			width: 286px;
			transform: translate(-24px, 0);
			border-radius: 0 6px 6px 0;
			box-shadow: 0 2px 28px $sage;
			transition: 0.4s ease;
		
			nav.policy-nav {
				opacity: 1;
				transform: translate(0, 0);
				scale: 1;
				transition: 0.4s ease, scale 0.3s ease 0.2s;
				overflow-y: auto;

				ul.top-level-parent {
					li.nav-item {
						a.nav-btn {
							&:hover {
								color: $sage;
								background-color: $onyx;
								box-shadow: 1px 1px 12px 0px $sage;
								cursor: pointer;
							}
						}
					}
				}
			}
		}
	}
	
	/*######### Privacy Policy #####and##### Terms and Conditions #######*/
	article#node-3340, //###  Privacy Policy ##
	article#node-3341 /*###  Terms and Conditions */ {
		position: relative;
	}

	/**###  TRMS & CONDSH ###-only-### ###**/
	article#node-3341 {
		.field-name-field-document .field-items {
			margin-top: 2em;
			margin-bottom: 2em;
			padding: 2em;
			background-color: #FFF;
			border-radius: 6px;
			box-shadow: 2px 2px 12px -2px $shadow_A;

			.field-item:before {
				content: "Download:";
				position: relative;
				display: block;
				padding-bottom: 12px;
				margin-top: -10px;
				font: {
					size: 24px;
					weight: 600;
				}
			}
		}
	}
	
	/*######### Privacy Policy #####and##### Terms and Conditions #######*/
	&.page-node-3340,
	&.page-node-3341 {
		.layout--flexible.layout.layout .layout-flexible-content {
			>.flexible-row > .container-fluid.region_12 {
				position: relative;
			}
			>div.flexible-row > .container-fluid.region_12 {
				>.l-flexible-row.row {
					>.l-col.col-md-12.l-region--content:before {
						content: "";
						position: fixed;
						height: 100%;
						width: 100%;
						background: {
							image: url(../images/bgs/bg-cty-05.webp);
							repeat: no-repeat;
							position: bottom;
							size: 100% auto;
						}
						top: 0;
						left: 0;
						transform: translate(0px, var(--prcntFl));
					}
				}
			}
		}
	}
	/*######### Privacy Policy ##### -ONLY- #########*/
	&.page-node-3340 {
		.layout--flexible.layout.layout .layout-flexible-content {
			>div.flexible-row > .container-fluid.region_12 {
				>.l-flexible-row.row {
					
					>.l-col.col-md-12.l-region--content:before {
						background: {
							image: url(../images/bgs/bg-cty-05.webp);
						}
					}
				}
			}
		}
	}
}

/****=====** CHART pages *-*MOBILE*FIRST*-* **=====****/
article#node-3309 {
	.field.field-name-body {
		p {
			line-height: 1.2;
			color: $drab;
		}
	}
}

.block.block-views-chart-items-block {
	.view.view-chart-items {
		.views-row {
			background-color: #FFF;
			margin: 18px -15px;
			padding: 0.8em 1.2em;
			box-shadow: 1px 2px 12px -4px $shadow_A;
			
			.views-field {
				h4 {
					color: $drab;
					font: {
						family: $smallFont;
						weight: 400;
						size: 22px;
					}
					
				}
			}
			
			.views-field-field-subtitle {
				h1 {
					margin-top: 6px;
					margin-bottom: 0.8em;
					color: $steelBlue;
					font: {
						family: $subFont;
						weight: 800;
					}
					line-height: 1.1;
				}
			}
			.views-field.views-field-body {
				p {
					margin: 0.4em auto 1em;
					color: $lightBlack;
					line-height: 1.4;
				}
			}
		}
	}
}
/******* CHART pages - capacity ratings ******+Mobile+1st+****/
article.node-page.view-mode-full {
	.chart-content {	
		background-color: #FFF;
		padding: 0.4em 1em;
		border-radius: 0;
		box-shadow: 2px 4px 14px -6px $shadow_A;
		
		>p {
			margin: {
				left: 1.5em;
				right: 1.5em;
			}
			color: $drab;
		}
		.table-container {
			width: 100%;
			overflow-x: auto;
			
			table.cap-rate.tbl-chart {
				tr {
					th, td {
						border-width: 1px 0;
						
						&:nth-child(4),
						&:nth-child(6),
						&:nth-child(8),
						&:nth-child(10) {
							border-left: {
								width: 1px;
								color: $ice2;
							}														
						}
					}
					
					td {
						font: {
							size: 12px;
							family: $chartFont;
						}
						white-space: nowrap;
						padding: 20px 10px;
						border-color: $sage;
						color: $drab;
						vertical-align: middle;
						line-height: 1.2;
						text-align: center;
					}
					
					&:nth-child(1) {
						td {
							&:nth-child(4),
							&:nth-child(5),
							&:nth-child(6),
							&:nth-child(7) {
								border-left: {
									width: 1px;
									color: $ice2;
								}														
							}
						}
					}
						
					th {
						background-color: $steelBluegreen;
						color: $ice;
						font: {
							family: $headingFont;
							size: 14px;
							weight: 400;
						}
						span.smllr {
							font-weight: 300;
							font-size: small;
						}
					}

					td:nth-child(2),
					td:nth-child(3) {
					    color: $onyx;
						font: {
							weight: 500;
							family: $subFont;
						}
						letter-spacing: 0.2px;
					}
				
					&.sct-2 {
						background-color: $ice;
					}
				}
			}
		}
		
		.chart-note {
			p {
				color: $steelBlue;
			    font-size: 0.9em;
				padding: 1em;
				
			}
		}
	}
}
span.thick {
    font-weight: 700;
}
/******* capacity ratings - Continued - UNDER main table -*** CHART pages ***+Mobile 1st+****/
.page-node-3314 {
	.block.block-views-chart-items-block {
		margin-top: 1.2em;
		
		.view.view-chart-items {
			.view-content {
				display: flex;
				align-items: stretch;
				flex-wrap: wrap;
				flex-direction: row;
				column-gap: 0.8em;
				row-gap: 1em;
				margin-bottom: 2.4em;
				
				.views-row {
				    flex: 1 2 35%;
					padding: 0.55em;
					margin: 0;
					
					.views-field.views-field-field-subtitle {
						position: relative;
						height: 48px;
						
						h1 {
							font-size: 1.5em;
						}
					}
					
					.views-field.views-field-field-body-2 {
						.field-content {
							svg {
								max-height: 260px;
								min-height: 140px;
							}
							.bottom-note {
								position: absolute;
								bottom: -26px;
								
								p {
									font-size: 1em;
									line-height: normal;
									color: $steelBlue;
									margin: 0.6em auto;
									width: 80%;
								}
							}
						}
					}
					
					&.Capacity-Ratings.views-row-2 {
						flex: 3 1 45%;
					
					}
				}
			}
		}
	}
}
.logged-in.page-node-3314 {
	.block.block-views-chart-items-block .view.view-chart-items {
		.view-content .views-row .views-field.views-field-field-body-2 .field-content {
			.bottom-note {
				transform: translate(0px, 32px);
			}
		}
	}
}
/********** --Components Finder-- - -page- +++ Mobile First +++ *******/
.view-display-id-component_filter.components.components-finder {	
	
	.view-filters {
		form.views-exposed-form {
			margin: {
				left: -15px;
				right: -15px;
				bottom: 14px;
			}
			background-color: #fff;
			box-shadow: 0px 3px 12px 2px $shadow_A;
		
			.views-exposed-form {
				padding: {
					left: 16px;
					right: 16px;
				}
				
				.views-exposed-widgets.clearfix {
					margin-bottom: 26px;
				}
				
				.views-exposed-widget > label {
					display: block;
					font: {
						family: 'Open Sans';
						weight: 100;
					}
					margin: 0.3em 1em 0.6em;
				}
				
				.views-widget .bef-checkboxes {
					display: flex;
					gap: 0.6em;
					flex-wrap: wrap;
					align-content: center;
					align-items: center;
					
					.form-item.form-type-bef-checkbox {
						flex: 2 3 28%;
						
						input {
							position: absolute;
							opacity: 0;
							cursor: pointer;
							width: 0;
							
							&[checked="checked"]~label.option {
								color: $ice;
								background-color: $steelBlue;
								
								&:hover {
									color: #FFF;
									background-color: $onyx;
									box-shadow: 4px 6px 22px -18px $shadow_A;
								}
							}
						}
						
						label.option {
							display: block;
							margin-bottom: 0;
							padding: 6px 16px;
							border: 2px solid $drab;
							border-radius: 100px;
							text-transform: capitalize;
							line-height: 1;
							color: $steelBlue;
							background-color: $ice;
							box-shadow: 8px 12px 32px -18px $ice;
							cursor: pointer;
							text-align: center;
							
							&:hover {
								color: $steelBluegreen;
								background-color: #FFF;
								border-color: $steelBlue;
								box-shadow: 4px 6px 22px -18px $shadow_A;
							}
						}
					}
				}
			}
		}
	}
	.view-content {
		.views-view-grid .views-grid-box {
			grid: {
				template-columns: 120px auto;
			    column-gap: 2px;
				row-gap: 6px;
			}
			padding: 28px 6px 12px;
			
			h2.views-field.views-field-field-subtitle {
				.subtitle {
					margin-top: 0;
					font: { 
						size: 23px;
						weight: 400;
					}
					letter-spacing: 0.6px;
					line-height: 1.2;
				}
			}
			
			.views-field.views-field-body {
				grid-column: 1 / span 2;
				
				ul {
					padding-left: 24px;
				
					li {
						line-height: 1.3;
						font-size: 16px;
						letter-spacing: 0.4px;
						font-weight: 400;
					}
				}
			}
		}
	}
}
/**** - chart page - unit load cap - **Only This Page** = - mobile first - *****/
body.page-node-3326 {
	.block.block-views-chart-items-block {
		.view.view-chart-items.view-id-chart_items {
			>.view-content {
			    display: grid;
				grid-template-columns: 1fr 1fr;
				
				.views-row {
					grid-column: 1 / span 2;
					
					span.emph.lbl {
						padding: 0;
						line-height: 1.2;
						font-size: 13px;
					}
					
					/***** shelving unit images *********/
					&.chart-part---beaded-front-post-unit,
					&.chart-part---h-post-unit {
						grid-column: auto;
						padding: 4px 8px;
						margin-bottom: 0;
						margin-top: 0;
						
						.views-field-field-hero-image {
							.field-content {
								height: 280px;
								margin-top: 12px;
								text-align: center;
								
								img {
								    max-height: 100%;
									width: auto;
								}
							}
						}
						.views-field-body {
							order: 2;
							max-width: 100%;
							
							p {
								text-align: center;
							}
						}
					}				
					/***** Beaded Post ** only - - - shelving unit images *********/
					&.chart-part---beaded-front-post-unit {
						margin-right: 0;
						padding-left: 10px;
					}
					/***** H-Post ** only - - - shelving unit images *********/
					&.chart-part---h-post-unit { 
						margin-left: 0px;
						padding-left: 12px;
					}					
					/***** Sway Brace - Heading Label ** only - - - Mobile First *********/
					&.Chart-Item---typical-sway-brace-install {
					    margin: {
							top: 2em;
							bottom: 0;
						}
						padding-bottom: 0;
						background-color: transparent;
						box-shadow: none;
						
						.views-field.views-field-body {
							.title-box {
								h2 {
									line-height: 1.2;
									margin-bottom: 6px;
								}
								
								h4 {
									text-align: left;
									line-height: 1.15;
								}
							}
						}
					}
					&.chart-part---cap-load-bottom-note {
						background-color: transparent;
						box-shadow: none;
						margin-top: 0;
						
						.views-field.views-field-body {
							max-width: 100%;
						}
					}
				}				
			}
		}
	}
}	
/******** post cap chart - - - TABLE Styles ##### ****/
article.node-page.view-mode-full {
	.chart-content {
		.table-container {
			table.tbl-chart tr {
				th {
					background-color: $steelBluegreen;
					border-right-color: $steelBluegreen;
					padding: 10px 12px;
					font-size: 12px;
					color: #fff;
				}
				td {
				    text-align: center;
					font: {
						size: 12px;
						family: $chartFont;
						weight: 500;
					}
					white-space: nowrap;
					padding: 13px 10px 10px;
					border-bottom-color: $sage;
					color: $drab;
					
					&.table-lable {
						padding: 9px 0 2px;
						font: {
							size: 24px;
							family: $subFont;
							weight: 800;
						}
						border-width: 12px 0 0;
						
					}
					&.table-txty {
						padding: 0 0 9px;
						border-width: 0;
						color: $drab;
						font: {
							size: 12px;
							family: $smallFont;
						}
						text-wrap: balance;
					}
					
					&.sct-lbl {
						padding: 9px 13px 8px;
						font: {
							size: 16px;
							family: $smallFont;
							weight: 700;
						}
						color: $steelBlue;
						background-color: $iceGray;
						
					}
				}
			}
		}
	}
}

/**** - chart pages - --- - unit load cap - **target** = - mobile first - *****/
.block.block-views-chart-items-block {
	.view.view-chart-items.view-id-chart_items {
	
		.view-content {
		
			.views-row {
			    display: flex;
				flex-wrap: wrap;
				
				.views-field {
					flex: 1 1 100%;
					
					.field-content  {
						.chart-caption {
							text-align: left;
							
							p {
								margin-bottom: 0;
								font: {
									size: 20px;
									weight: 300;
								}
								color: $steelBlue;
							}
							ul {
								margin-top: 8px;
							}
						}
					}
				}
				p { color: $onyx}
				ul {
					padding-left: 16px;
					
					li {
						line-height: 1.2;
						text-align: left;
						text-indent: -4px;
						color: $onyxLte;
					}
				}
				span.emph {
				    padding: 6px 12px;
					font: {
						size: larger;
						weight: bolder;
						family: $subFont;
					}
					color: $steelBlueGray;
					line-height: 1.7;
				}
				em,
				span.emph em {
					color: $steelBlue;
				}
	
				.views-field-field-subtitle {
					h1 {
					    text-align: center;
					}
				}
				.views-field-body {
					max-width: 80%;
					margin: auto;
				}
			
				/**=== Section 1 - unit load cap - chart **-** MOBILE FIRST -===**/
				&.views-row-1 {
					.views-field-field-subtitle {
						order: 1;
					}
					
					.views-field-body {
						order: 3;
					}
					
					/**=== svg chart ===**/
					.views-field-field-body-2 {
						order: 2;
						
						.chart {
							svg#unitLoadCap {
							    margin: {
									top: 0.3em;
									bottom: 1.2em;
								}
							}
							svg#legend-items {
								max-height: 80px;
								margin-top: 16px;
							}
						}
					}
				}
			}
		}
	}
}

/***** global title rules ***--mobile first--***/
.block-title,
.view-header h3,
.view-header h3 a {
	width: auto;
	font: {
		size: 32px;
		family: $headingFont;
		weight: 300;
	}
	text-align: center;
	color: $drab;
	text-transform: uppercase;
	letter-spacing: 0;
}
.view-header  {
	h3 {
		margin: 40px auto 6px;
	}
	p {
		margin: 0;
		padding: 0;
	}
}

.view-grouping-content h3 {
    border-bottom: 2px solid #79aeb1;
    margin: 0.7em 1% 0.3em;
    padding: 2px 0.5em 4px;
    color: #87bcbf;
}
/*++++++++++---- bread crumbs - Mobile First - ****** */

.view-taxonomy-term .views-view-grid .views-grid-box  ,.view-child-category .views-view-grid .views-grid-box {
		width: calc(50% - 20px);
		margin: 0 10px 10px !important;
}
.view-child-category .item-list.product-landing-list.collapsed {
    transition: .3s ease;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
}

.layout .flexible-row .row .block-content {

	/*++++++++++---- TOP TEXT - Mobile First - ****** */	
	.topText.view.view-home-page-slider .view-content .item-list {
		background-color: transparent;
	}
}

/****** Support pages - general - mobile first ********/
.block.block-menu-menu-support-navigation {
	ul.menu-dropdown.menu li.leaf.active-trail a {
		background-color: $drab;
		color: $sage;
		text-shadow: 1px 1px 2px $drab;
		transition: text-shadow 360ms ease;
		
		&:hover,
		&:active {
			text-shadow: 1px 1px 2px $onyx;
			transition: text-shadow 200ms ease;
		}
	}
}

/****** New & Event pages - mobile first ********/
.block-views-news-and-event-slider-block {
  clear: both;
}
.news-event-slider-block .block-title {
  margin: 10px auto;
  clear: both;
  display: inline-block;
  width: 100%;
}

.slick-center-mode {
	.thumbnail {
		background: white;
		position: relative;
		width: 100%;

		img {
			width: 100%;
			height: auto;
		}

		.type {
			position: absolute;
			bottom: 0px;
			left: 0px;
			background: #1c1c1cad;
			color: white;
			padding: 5px 10px;
			margin: 0px;
			width: 50%;
			border-radius: 0px 10px 0px 0px;
			line-height:1;
		}
	}
	li {
		padding: 0px 10px;
	}
	h3.title {
		line-height: 1;
		height: 1.7em;
		margin: 10px 0px;

		a {
			font: {
				size: 20px;
				family: $headingFont;
			}
		}
	}
	p {
		margin: 10px 0px;
	}
	.slick-list {
		padding: 0px 5% 0px 5% !important;
	}
	.article {
		.event-date {
			display: none;
			width: 0px;
			height: 0px;
		}
	}
	.post-date, .event-date {
		font-size: 14px;
	}
	.event-date {
		span.date-display-range {
			padding-left: 5px;
		}
	}
	.links {
		text-align: right;
		margin-top: 1.5em;

		a {
			padding: 0px 60px 0px 0px;
			height: 2em;
			margin-top: -20px;
			display: block;
		}
		a:hover {
			opacity: 0.65;
		}
		a:after {
			content: " ";
			height: 60px;
			background: url(/themes/hallowell_theme/images/arrow-circled-right.png) no-repeat center right;
			display: block;
			margin-top: -43px;
			bottom: 0;
			margin-right: -60px;
		}
	}
	.event {
		.post-date {
			display: none;
			width: 0px;
			height: 0px;
		}
	}
	.slick-arrow {
		display: inline-block;
		text-indent: -9999px;
		width: 50px;
		height: 50px;
		border-radius: 100%;
		border: none;
		opacity: .3;
		cursor: pointer;
		position: absolute;
		top: calc(50% - (45px/2));
		z-index: 99;
		margin-top: 0;
		transition: all ease 0.5s;
	}
}
h1, h2, h3, h4, h5, h6 {
  margin: 0;
}
.view.view-products-displays-by-category {
	.views-view-grid {
		.views-grid-box {
			img {
				border-radius: 4px 4px 0 0;
				border: solid transparent 1px;
			}
		}
		.views-grid-box.row-last {
			margin-right: 0;
		}
	}
}
.view-child-category {
	h3, .categories-sub-title{
		margin: 0 0 6px 0;
		text-align: left;
		font-size: 24px;
		font-family: $headingFont;
		font-weight: lighter;
		color: $drab;
		text-transform: uppercase;
		padding-left: 20px;

	}
	ul, ul li{
		margin: 0;
		padding: 0;
		list-style-type: none;
	}

	.product-landing-list {
		overflow: hidden;
		position: relative;
		width: calc(100% + 3.75em);
		margin: {
			left: -1.875em;
			bottom: 20px;
		}

		.slick-arrow {
			display: inline-block;
			text-indent: -9999px;
			width: 50px;
			height: 50px;
			border-radius: 100%;
			border: none;
			opacity: .3;
			cursor: pointer;
			position: absolute;
			top: calc(50% - (45px/2));
			z-index: 99;
			margin-top: 0;
			transition: all ease 0.5s;
		}
		.swiper-button-prev, .swiper-button-next {
			background: {
				position: center;
				size: contain;
				color: #FFF;
			}
			transform: translate(0%,-50%);
			width: 60px;
			height: 60px;
			border-radius: 100%;
			opacity: .6;
			transition: .3s ease;
		}
		.swiper-button-prev  {
			left: 42px;
			background: {
				image: url(/themes/hallowell_theme/images/arrow-left.png);
				repeat: no-repeat;
			}
		}
		.swiper-button-next  {
			background: {
				image:  url(/themes/hallowell_theme/images/arrow-right.png);
				repeat: no-repeat;
			}
			right: 41px;
		}
		.slick-arrow:hover {
			opacity: .8;
		}
		.swiper-button-prev:hover, .swiper-button-next:hover,
		.swiper-button-prev:active, .swiper-button-next:active,
		.swiper-button-prev:focus, .swiper-button-next:focus {
			opacity: .8;
			transition: 1.6s ease;
		}

		h3 {
			padding-left: 40px;
		}
		.views-field-name a {
			display: block;
			text-decoration: none;
			font: {
				family: $labelFont;
				weight:400;
				size: 14px;
			}
			text: {
				decoration: none;
				align: center;
			}
			letter-spacing: normal;
			line-height:1.2;
		}
		li {
			padding: 0 7px;
			transition: all ease .5s;
		}
		.no-slide {
			justify-content: space-between;

			li {
				width: calc(20% - 10px);
			}
		}
	}
	.views-field-field-logo {
		a {
			display: block;
			position: relative;
			background-color: white;
			border-radius: 6px;
			box-shadow: 2px 2px 6px $shadow_A;
			margin-bottom: 8px;
			overflow: hidden;

			img {
				display: block;
				width: calc(100% - 40px);
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				transition: cubic-bezier(0, 0.14, 0, 0.9) 0.3s;
			}
		}
		a:hover  img {
			width: calc(100% - 20px);
		}
		a.empty-product {
			background-color: #f4f4f4;
		}
		a::before {
			display: block;
			content: "";
			padding-bottom: 56%;
		}
	}
	.views-view-grid {
		display: flex;
		width: calc(100% + 40px);
		margin-left: -20px;
		flex-wrap: wrap;

		.views-grid-box {
			border: none;
			width: calc(20% - 40px);
			margin: 0 20px 20px;
			transition: all ease .5s;
			padding: 0;

			.views-field-field-logo {
				background-color: white;
				border-radius: 20px;
				box-shadow: 2px 2px 6px $shadow_A;
				margin-bottom: 10px;
				overflow: hidden;

				a {
					display: block;
					position: relative;
					overflow: hidden;

					img {
						width: calc(100% - 40px);
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%,-50%);
					}
				}
				a:before {
					display: block;
					content: "";
					padding-bottom: 56%;
				}
			}
			.views-field-name {
				a {
					text: {
						align: center;
						decoration: none;
					}
					font: {
						family: $headingFont;
						size: 26px;
					}
					display: block;
				}

				.no-parent img {
					display: none;
				}
				.cat-title-wrap.has-parent  {
					position: relative;
					display: flex;
					justify-content: space-between;
					align-items: flex-end;

					img {
						width: 20vw;
						max-width: 280px;
					}
				}
			}
		}
	}
}

.view.view-taxonomy-term {
	padding: 0 10px;

	.view-header {
		h3 {
			padding-left: 24px;
		}
	}

	.views-view-grid {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;

		.views-grid-box {
			transition: all ease .5s;
			border: none;
			background-color: transparent !important;
			padding: 10px !important;
			margin: 0 !important;
			width: 50%;
			
			.views-field-field-product-images {
				a {
					display: block;
					position: relative;
					overflow: hidden;
					background-color: white;
					border-radius: 6px;
					box-shadow: 2px 2px 6px $shadow_A,
								 inset 0 0 20px -2px $iceGray;
					margin-bottom: 10px;
					width: 100%;
					height: auto;
					transition: 200ms ease;

					img {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%,-50%);
						height: 88%;
						width: auto;
						mix-blend-mode: multiply;
						transition: cubic-bezier(0, 0.14, 0, 0.9) 300ms;

					}
				}
				a::before {
					display: block;
					content: "";
					padding-bottom: 100%;
				}
				
				a:hover {
					box-shadow: 2px 2px 6px $steelBlue,
								 inset 0 0 20px 2px $iceGray;
					transition: 360ms ease;
					
					img {
						height: 95%;
					}
				}
				 a:active {
					img {
						height: 98%;
						transition: 60ms ease 200ms;
					}
				}
			}
			.taxonomy-name {
				background-color: transparent ;
				padding: 0;
				line-height: 1.3;
				height: auto;
				margin-bottom: 12px;

				a {
					display: block;
					font: {
						family: $labelFont;
						weight: 500;
						size: 14px;
					}
					text-decoration: none;
					text-align: center;
					color: $onyx;
					height: auto;
					letter-spacing: normal;
				}
			}
		}
		.views-grid-box:hover {
			box-shadow: none;
		}
	}
}
/* ############## locker gallery ###### Mobile First ####### */

article.node-gallery .field-name-field-gallery-images.field-type-image {
	.field-items {
		display: grid;
		padding: 8px;
		grid: {
			template-columns: 1fr 1fr 1fr;
			gap: 16px;
		}
		text-align: center;
		
		.field-item {
			height: 100%;
			width: 100%;
			overflow: hidden;
			border: {
				radius: 8px;
				width: 4px;
				color: white;
				style: solid;
			}
			box-shadow: 4px 3px 14px -3px #87bcbf;
			transition: .3s;
			
			a {
				height: 100%;
				width: 100%;
				display: block;
				margin: 0;
				padding: 0;
				
				img {
					min: {
						height: 110%;
						width: 100%;
					}
					max-width: 120%;
					height: auto;
					mix-blend-mode: multiply;
				}
			}
		}
		.field-item:hover {
			border: {
				color: orange;
				width: 1px;
			}
			box-shadow: 14px 20px 24px -5px gray;
		}
	}
} /* ###### END ###### locker gallery ##### END #### Mobile First #### */


.view.view-taxonomy-term .views-view-grid  .prod-block-Quickship {
	display: none;
}
.slick-slider {
  position: relative;
}
/* ++======= Accessories pages - START - MOBILE FIRST -- ---+++++=======++ */
body.page .l-col.col-md-12.l-region--content {

/* ##### Accessories NAVIGATION - MOBILE FIRST ###### */
	.block.accessories-nav {
		.block-content {
			ul.menu-tree.menu {
				display: grid;
				grid: {
					gap: 6px;
					template-columns: 1fr;
				}
				padding: 0;
				margin: auto;
				
				li.leaf {
					list-style: none;
					margin: 0;
					
					a {
						display: block;
						padding: 4px 7%;
						text-decoration: none;
						background-color: $sage;
						color: $steelBlue;
						font: {
							size: 15px;
						}
						border: {
							color: $steelBluegreen;
							style: solid;
							width: 2px 0;
						}
						transition: all 350ms ease, 
							border 150ms, 
							background-color 450ms ease-in-out
						;
					}
					a.active-trail.active {
						color: $steelBluegreen;
						background-color: $drab;
						padding-left: 12%;
					}
					a:active, a:hover {
						background-color: $drab;
						border-color: $sage;
						color: $sage;
						transition: all 650ms ease, 
							border 2s linear, 
							background-color 1s ease-out, 
							color 0s
						;
					}
					
				}
				li.first.leaf.acc-main {
					a { 
						background-color: transparent;
						border-color: transparent;
						color: $sage;
					}
					a::before {
						content: "";
						display: block;
						border: solid $sage;
						border-width: 1px 0 0 1px;
						height: 6px;
						width: 6px;
						position: absolute;
						transform: rotate(-45deg) translate(0px, 12px);
						left: 3%;
						transition: 150ms ease;
					}
					a:active, a:hover {
						background-color: $ice;
						color: $steelBluegreen;
						
					}
					a:active::before, a:hover::before {
						border-color: $steelBluegreen;
						left: 2%;
						transition: 250ms ease;
					}
				}
			}
		}
	} /* --===###===-- END --Acc Nav - Items Pages - MOBILE FIRST --==###==-- */		
	

	/* ++====###=== Accessories (section) pages - MOBILE FIRST -- ---+++++==###=====++ */
	.block .view.view-accessories-items {
		.view-content {
			display: grid;
			width: 100%;
			grid: {
				gap: 38px 20px;
				template: {
					columns: auto;
					rows: auto;
				}
			}
			.views-row {
				display: grid;
				width: 100%;
				
				.views-field-field-hero-image {
					width: 100%;
					height: auto;
					
					.field-content a {
						position: relative;
						overflow: hidden;
						display: block;
						border-radius: 6px;
						
					}
				}
				
				.views-field-field-subtitle {
					h4 {
						font: {
							family: $bodyFont;
							weight: 300;
						}
						color: $drab;
						text-transform: capitalize;
					}
				}
				
				.views-field-body {
					max-width: 100%;
					overflow: hidden;
					
					.field-content p {
						line-height: 1.2rem;
						font: {
							size: 0.85rem;
							family: $smallFont;
							weight: 500;
						}
						color: $steelBlue;
					}
				}
				
				.views-field-field-document {
					.field-content span.file {
						a {
							font: {
								weight: 500;
								size: .86rem;
							}
							text-decoration: none;
							color: $steelBluegreen;
						}
						a:active,
						a:hover {
							color: $sage;
						}
						a::after {
							@include hovUnderOff;
						}
						a:hover::after, 
						a:active::after {
							@include hovUnderOn;
							color: $sage;
						}
					}
				}	
			}
		}
	}/* --===###===-- END -- base styles - all each page - MOBILE FIRST --==###==-- */
	
	/* ++======= Accessories page - Benches & Pedestals Block -- MOBILE FIRST -- ---+++++=======++ */
	.block.block-views-accessories-items-block-benches {
		.block-content {
			.view-accessories-items .view-content {
				margin-top: 48px;
				.views-row {
					grid-template-areas:
						'hero'
						'sub'
						'body'
						'doc';
					border-bottom: 3px dashed;
					border-color: #dee2ea;
					padding-bottom: 12px;
					
					.views-field-field-hero-image {
						grid-area: hero;
						
						.field-content {
							height: 100%;
							
							a {
								margin-bottom: 8px;
								background-color: #fff;
								transition: 300ms ease-out;
								border-radius: 6px;
								box-shadow: 2px 3px 22px -10px #d7d9db;
								
								img {
									position: relative;
									mix-blend-mode: multiply;
									max-height: 100%;
									height: auto;
									width: auto;
									top: 50%;
									left: 50%;
									transform: translate(-50%, -50%);
									transition: 300ms ease-out;
								}
							}
							
							a:hover, 
							a:active {
							    box-shadow: 2px 2px 12px -2px #00658559,
									inset 1px 1px 99px -2px #87bcbfbf,
									inset 0px 0px 24px -8px #86dadf;
								transition: 200ms ease;
								
								img {
									transition: 200ms ease;
								}
							}
						}
					}
					.views-field-field-subtitle {
						grid-area: sub;
					}
					.views-field-body {
						grid-area: body;
						
						.field-content p {
						    margin-top: 4px;
						}
					}
					.views-field-field-document {
						grid-area: doc;
					}
				}
				.views-row.benches {
					.views-field-field-hero-image {
						.field-content a {
							overflow: hidden;
							height: 153px;
							position: relative;
							display: block;
							background-color: #FFF;
						
							
							img {
							    max-height: 200%;
								max-width: 100%;
							}
						}
					}
					.views-field.views-field-body {
						.field-content { 
						
							table {
								background-color: #fff;
								border: 2px solid #dee2ea;
								
								tr td {
									border: {
										width: 2px 0;
										color: #dee2ea;
									}
								}
							}
						}
					}
					.views-field.views-field-field-document {
						padding: 0;
					}
				}
				
				.views-row.accessories.pedestals {
					.views-field.views-field-field-hero-image {
						width: 100%;
						
						.field-content a {
							height: 200px;
							width: 100%;
							padding: 12px;
							background-color: #fff;
							
							img {
								max: {
									height: 100%;
									width: 100%;
								}
							}
						}
					}
					.views-field.views-field-field-subtitle {
						padding: 0;
						
						h4.field-content.title.subtitle {
							font: {
								family: $bodyFont;
								size: 1.2rem;
							}
							line-height: 1.4rem;
						}
					
					}
					.views-field.views-field-field-document {
						padding: 0;
					}
				}
			}
		}
	}/* --===###===-- END -- page - Benches & Pedestals Block - MOBILE FIRST --==###==-- */
	
	/* --===###===-- LOCKER OPTIONS - Accessories page -MOBILE FIRST --==###==-- */	
	.block.block-views-accessories-items-block-options {
		.view.view-accessories-items.accessories-page-items {
			margin-left: -0.875rem;
			margin-right: -0.875rem;
			.view-content {
				.views-row.accessories.locker-options {
					margin-bottom: 32px;
					background-color: #ffffffbf;
					width: 100%;
					padding-top: 24px;
					padding-bottom: 16px;
					box-shadow: 0 2px 12px -4px #0065859e;
					
					.views-field {
						padding-left: 24px;
						padding-right: 24px;
						
						.field-content {
							height: inherit;
							width: inherit;
						}
					}
					.views-field.views-field-field-subtitle h4 {
						margin-top: 22px;
						text-transform: capitalize;
						color: $drab;
					}
					.views-field.views-field-field-hero-image {
						width: 100%;
						height: 100%;
						
						.field-content {
							height: inherit;
							width: inherit;
							
							a {
								position: relative;
								display: block;
								height: 200px;
								overflow: hidden;
								background-color: #FFF;
								border: {
									color: $steelGray;
									style: solid;
									width: 1px;
									width: .065rem;
									radius: 8px;
								}
								box-shadow: 
										4px 6px 12px -5px #3247559e, 
										inset 1px 1px 44px -20px #324755c9
								;
								transition: .4s;
							
								img {
								    max-height: 300px;
									width: auto;
									left: 50%;
									top: 50%;
									position: relative;
									transform: translate(-50%, -50%);
									mix-blend-mode: multiply;
									transition: .3s;
								}
							}
							a:hover, a:active {
								box-shadow: 5px 18px 23px -8px #324755d1,
									inset 1px 1px 44px -20px #87bcbf;
								border-color: $linkBlue;
								transition: 100ms, box-shadow 850ms ease-out;
								
								img {
								    max: {
										height: 324px;
										width: 120%;
									}
									transition: .5s ease-out;
								}
							}
						}
					}
					.views-field.views-field-body .field-content {
						p {
							line-height: 1.2rem;
							font: {
								size: 0.85rem;
								family: $smallFont;
								weight: 500;
							}
							color: $steelBlue;
						}
					}
				}
			}
		}
	}

	/* --===###===-- LOCKS - Accessories page -MOBILE FIRST --==###==-- */	
	.block.block-views-accessories-items-block-locks {
		margin-right: -15px;
		margin-left: -15px;
		
		.block-content {
			.view.view-display-id-block_locks {
				.view-content {
					display: grid;
					width: 100%;
					grid-gap: 32px;
					
					.views-row.accessories.locks {
						display: grid;
						width: 100%;
						padding: 8px 15px;
						border: {
							color: $steelBluegreen;
							style: solid;
							width: 2px 0;
						}
						background-color: $sageLte;
						
						.views-field.views-field-field-hero-image {
							width: 100%;
							
							.field-content {
								height: 100%;
								
								a {
									position: relative;
									display: block;
									height: 100%;
									border-radius: 8px;
									box-shadow: inset 0 0 44px -18px $ice2;
									transition: 360ms ease;
									
									
									img {
										position: relative;
										padding:8px;
										top: 50%;
										left: 50%;
										transform: translate(-50%, -50%);
										max-height: 100%;
										width: auto;
										mix-blend-mode: multiply;
										transition: 360ms ease;
									}
								}
								a:hover, a:active {
									border-color: #aecacc;
									box-shadow: inset 0 0 44px -6px $sage;
									transition: 240ms ease;
									
									img {
										padding:2px;
										transition: 240ms ease;
									}
								}
							}
						}

						.views-field.views-field-field-subtitle {
							h4 {
								margin-top: 16px;
								line-height: 1.6rem;
								font: {
									family: $chartFont;
									size: 1.3rem;
								}
								text-transform: capitalize;
								color: $drab;
							}
						}
						.views-field.views-field-body .field-content {
							p {
								line-height: 1.2rem;
								font: {
									size: 0.85rem;
									family: $smallFont;
									weight: 500;
								}
								color: $steelBlue;
							}
						}
					}
					.views-row.accessories.locks.odd {
						background-color: $iceGray;
					}
				}
			}
		}
	}/* --===###===-- END --Accessories (sections) pages - MOBILE FIRST --==###==-- */
}/* --===###===-- END --body ---Accessories pages --==###==-- */


/* +++++###++ START --Accs Nav - LandingPage - MOBILE FIRST ++==###==++ */	
body.page.page-node-3228 .l-col.col-md-12.l-region--content {
	.block.accessories-nav {
		.block-content ul.menu-tree.menu li.first.leaf.acc-main {
			display: none;
		}
	}/* --===###===-- END --Acc Nav - LandingPage - MOBILE FIRST --==###==-- */	
	
	/* +++++###++ START --Acc LandingPage - Main Hero Image - MOBILE FIRST ++==###==++ */	
	.block.block-views-accessories-items-block-mainhero {
		.block-content .view-accessories-items .view-content {
			.views-row.mainpage {
				.views-field-field-hero-image .field-content {
					margin-top: 24px;
				}
			}
		}
	}/* --===##===-- END --Acc LandingPage - Main Hero Image - MOBILE FIRST --==##==-- */	
	
	/* +++++####++ START --Acc LandingPage - Tile Links - MOBILE FIRST ++==####==++ */
	.block.block-views-accessories-items-block-1 .block-content .view.acc-main {
		.view-content {
		    row-gap: 14px;
			.views-row.mainpage {
				display: block;
				position: relative;
				
				.views-field.views-field-title {
					.field-content.acc_tile {
						a {
							display: grid;
							grid: {
								gap: 20px;
								template-columns: 120px auto;
							}
							align-items: center;
							text: {
								align: left;
								decoration: none;
							}
							background-color: $iceGray;
							overflow: hidden;
							border: {
								style: solid;
								width: 2px;
								color: $steelGray;
								radius: 4px;
							}
							transition: 200ms ease, background-color 0s;
							
							.acc_img {
								position: relative;
								display: block;
								height: 80px;
								width: 100%;
								overflow: hidden;
								
								img {
									position: absolute;
									padding: 0;
									width: auto;
									top: 50%;
									left: 50%;
									transform: translate(-50%, -50%);
									mix-blend-mode: multiply;
									transition: 200ms ease;
								}
							}
							.acc_title {
								letter-spacing: 1.8px;
								transition: 220ms ease;
							}
						}
						a:hover, 
						a:active {
							background-color: $ice;
							border-color: $sage;
							box-shadow: 
								inset 0px 0px 32px -6px #87bcbf,
								5px 5px 16px -2px #aecacc;
							transition: 300ms ease, background-color 0s;
							
							.acc_img {
								img {
									width: auto;
									padding: 4px;
									transition: 300ms ease;
								}
							}
							.acc_title {
								font-weight: bolder;
								color: $linkBlue;
								letter-spacing: 2.2px;
								margin-left: -2px;
								transition: 320ms ease;
							}
						}
					}
					/* ##-Acc LandingPage - 1st tile -  Tile Links  - MOBILE FIRST ++==####==++ */
					.field-content.acc_tile.acc_tile1 {
						a .acc_img img {
							top: 40%;
							max-width: 110%;
						}
					}
				}
				
			}
		}
	}
}

/* - #### block content - ###*/
/* shelving systems MOBILE 1st -- ========= */

.block-views-home-page-slider-block-5 {
/* -======---Shelving Systems----|||- ========== */
	.home-shelving-system {
		margin-bottom: 30px;

		ul li, ul {
			list-style-type: none;
			margin: 0;
			padding: 0;
		}
		.field-content .row {
			width: 100%;
			display: grid;
			grid-template-rows: 180px auto;
			background-color: $sage;

			.col-6 {
				width: 100%;
				height:100%;
				overflow: hidden;

				p {
					margin-top:0;
					margin-bottom:0;
					text-align: center;
				}
				img {
					display: block;
					width: 100%;
				}
				.text-line-1 {
					color: $onyx;
					font-size: 18px;
					margin: 0;
					font: {
						family: $bodyFont;
						weight: 300;
					}
					order:2;
				}
				.text-line-2 {
					color: $drab;
					font: {
						weight: 800;
						family: $headingFont;
						size: 38px;
					}
					text-transform:uppercase;
					line-height: 1.1;
					margin: 0 0 7px 0;
					order:1;
				}
				.text-line-3 {
					color: $onyx;
					font: {
						size: 14px;
						family: $bodyFont;
						weight: 400;
					}
					margin: 0 auto 5px;
					order:5;
				}

				@import "btn_onyxBg-sageTxt";
			}
			.col-6:nth-child(2) {
				display: flex;
				flex-flow: column;
				flex-direction: column;
				width: 100%;
				padding: 20px;
				box-sizing: border-box;
			}
		}
	}
}

/* ===---Lockers Gallery block on pages - MOBILE FIRST ==== */
.view.view-home-page-slider  {
	margin-bottom: 0px;

	.item-list {
		padding: 2px 0;
		background-color: $drab;

		ul, ul li {
			padding: 0;
			margin: 0;
			list-style-type: none;
		}
		.row.locker-gallery-blk {
		    display: grid;
			height: 620px;
			grid-template-rows: auto 340px;
			overflow: hidden;
			align-items: center;
			background-color: $onyxLte;

			.col-6.imgBlk {
				position: relative;
			    height: 100%;
				width: 100%;
				overflow: hidden;
				
				img {
					position: absolute;
					display: block;
					min: {
						width: 100%;
						height: 120%;
					}
					max-width: unset;
					height: 130%;
					width: auto;
					top: 30%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
			}
			.col-6.txtBlk {
				display: grid;
				grid: {
					template: {
						areas: 	'line2'
								'line1'
								'button'
								'line3'
								'line4'
								'line5';
					}
				}
				padding: 0 20px;
				width: 100%;
				height: 100%;
				overflow: hidden;
				box-sizing: border-box;
				justify-content: center;
				align-content: center;
				background: {
					image: url(/files/images/locker-installs-bg-001.png);
					size: 120%;
					repeat: no-repeat;
					position: center 110%;
				}
				p {
					text-align: center;
					width:100%;
					color: $ice;
					font: {
						family: $bodyFont;
						weight: 300;
						size: 18px;
					}
					margin: 0;
					padding: 0;
					line-height: 1.2;
					
				}
				.text-line-1 {
					grid-area: line1;
					font: {
						family: $headingFont;
						weight: 600;
						size: 32px;
					}
					color: $sage;
					line-height: 1.15;
					text: {
						align: center;
					    shadow: 1px 2px 3px #000;
					}
					margin: 12px auto 10px;
				}
				.text-line-2 {
					grid-area: line2;
					font-size: 20px;
				}
				.text-line-3 {
					grid-area: line3;
					font: {
						style: italic;
						size: 16px;	
					}
					color: $sage;
				}
				.text-line-4 {
					grid-area: line4;
				}
				.text-line-5 {
					grid-area: line5;
				}
				.button-link-wrap {
				    grid-area: button;
					padding: 5px;
					width: auto;
					text-align: center;
					
					a {
						position: relative;
						padding: 5px 40px;
						background-color: $sage;
						border: {
							radius: 100px;
							width: 2px;
							style: solid;
							color: $onyx;
						}
						display: inline-block;
						color: $onyx;
						font: {
							size: 14px;
							weight: 400;
						}
						text-decoration: none;
						transition: 0.5s ease-out;
						cursor: pointer;
					}
					a:active, a:hover, a:focus {
						background-color: #000;
						color: $sage;
						cursor: pointer;
						opacity: .75;
						transition: 0.35s ease;
					}
					a:active {
						transition: 1.5s ease;
						border-color: #38bfc7b8;
						opacity: .9;
						font-weight: 600;
						background-color: #245254cf;
						box-shadow: inset 3px 3px 16px -5px #0d0e0ed1;
					}
				}
			}
			.col-6.txtBlk::after, .col-6.txtBlk::before {
				content: none;
			}
		}
		.row.locker-gallery-blk::after {
			content: none;
		}
	}
}
/* #######=#= Info pages (Greenguard, etc.) =###### */
.node-type-options {
	article {
		padding: 24px;
		margin: auto;
		max-width: 1200px;
		.content {
			.field.field-name-field-logo img {
				width: 300px;
				float: right;
				padding: 12px 32px 12px 12px;
			}
	
			h1 {
				margin: 2px 28px 18px;
				padding-top: 4px;
				font-family: $headingFont;
				color: $drab;
				text-transform: capitalize;
			}
			h2 { 
				margin: 2px 28px 8px;
				color: $steelBlue;
				text-transform: capitalize;
			}
			h3 {
				margin: 12px 28px 6px;
				color: $steelBluegreen;
				text-transform: capitalize;
			}
			h3.subheading {
				text-align: center;
				font-family: $chartFont;
			}
			p.p-content-box {
				margin: 2px 48px;
			}
			table.content-table {
				max-width: 90%;
				min-width: 490px;
				width: 50%;
				margin: 10px auto;
				
				tr td {
					font-family: $smallFont;
					border: 3px solid $sage2;
					color: $steelGrayDrk;
				}
			}
			.content-box {
				padding: 12px 24px 24px;
				margin: 12px auto;
				border-radius: 6px;
				box-shadow: $shadow_A 2px 2px 24px -6px;
				background-color: #fff;
				
				p {
					color: $onyx;
				}
			}
		}
	}
}
	/***** - - Quickship Page - - ** MOBILE FIRST ****/
.node-type-options.page-node-2683 {
	.l-flexible-row.row {
		.l-col.col-md-12.l-region--content {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			column-gap: .9em;
		}
		
		/***** Quickship Page - LEFT COLUMN - ** MOBILE FIRST ****/
		article#node-2683 {
			margin: 1.5em auto 2em;
			padding: 24px 0;
			float: none;
			flex: 3 0 33%;
			
			.content.clearfix {
		
			/***** Quickship Page - logo image - ** MOBILE FIRST ****/
				.field.field-name-field-logo.field-type-image {
					margin-bottom: 20px;
					margin-top: 10px;
					
					.field-items .field-item {
						text-align: center;
						
						img {
							width: 300px;
							float: right;
							padding: 3px 32px 19px 12px;
							z-index: 100;
							position: relative;
							top: -10px;
						}
					}
				}
				.field.field-name-body.field-type-text-with-summary.field-label-hidden {
					.field-items .field-item {
						.l-col.col-md-4 {
							width: 100%;
							max-width: 100%;
							padding: 10px;
							
							div.left-column {
								margin-top: -54px;
								
								h2 {				 
									clear: right;
									text-shadow: 1px 1px 2px $onyx;
								}
								h3 {
									margin-bottom: 1em;
									text-shadow: 1px 1px 2px $onyx;
									p {
										font-size: 1.2em;
										font-family: $linkFont;
										letter-spacing: 1.2px;
										text-shadow: 1px 1px 1px $onyx;
										padding: 0.2em 0;
									}
								}
								p {
									padding: 1em 2em;
									line-height: 1.4;
								}
							}
						}
					}
				}
			}
		}
	
	
		/***** Quickship Page - right column - MOBILE FIRST - ******/
		.block.block-views-products-displays-by-category-block-13 {
			flex: 2 0 63%;
			.view.view-products-displays-by-category.view-id-products_displays_by_category.view-display-id-block_13.right-column {
				padding-left: 0;
				padding-right: 0;
				max-width: 100%;
				
				.view-content {
					display: flex;
					flex-direction: column;
					row-gap: 3em;
				}
				
				/***** Quickship Page - product tiles - MOBILE FIRST - ******/
				.views-row {
					.field-content {
						.tileBox {
							display: grid;
							grid: {
								template: {
									columns: auto auto;
									rows: auto auto;
								}
								gap: 4px 9px;
							}
							padding: 12px;
							
							.qsHero {
								position: relative;
								height: auto;
								width: auto;
								max: {
									height: 96%;
									width: 90%;
								}
								min-width: 50%;
								padding: 0 0 0 4px;
								float: none;
								align-self: center;
								overflow: hidden;
								margin: auto;
								
								a {
									position: relative;
									display: block;
									height: 100%;
									width: 100%;
									max-height: 100%;
									max-width: 100%;
									
									img {
										display: block;
										position: relative;
										max-height: 100px;
										width: auto;
										min-width: auto;
									}
								}
							}
							.qsrow.row {
								position: relative;
								margin-right: 0;
								min-height: 100px;
								max-width: 100%;
								margin: auto;
								
								h2.qsTitle {
									.qsName  {
										padding-bottom: 4px;
									}
									
								}
								.qsDesc {
									p {
										font: {
											size: 14px;
											weight: 300;
										}
										margin-top: 6px;
										margin-bottom: 6px;
										line-height: 1.2;
									}
								}
							}
							.qsLink {
								display: block;
								margin: auto;
								width: 100%;
								max-width: 280px;
								grid-column: 1 / 3;		
									
								a {
									padding: 5px 5px 3px 6px;
								}
								a:after {
									margin-top: 6.5px;
								}
							}
						}
					}
				}
				.views-row.views-row-5.last .field-content {
					.tileBox {
						.qsHero {
							padding-top: 15px;
							padding-right: 0px;
							margin-left: -4px;
							min-width: 100px;
						}
						.qsrow.row {
							h2.qsTitle {
								.qsName {
									margin-left: -3px;
									letter-spacing: .2px;
								}
							}
									
							.qsDesc { 
								padding-top: 0;
							}
						}
					}
				}
			}
		}
	}
	.block.block-system-title-combo {
		order: 5;
	}
}
.node-type-options.page-node-2683.logged-in {
	.block.block-system-title-combo {
		order: 1;
		flex: 0 0 100%;
		width: 100%;
	}
}

/* +++=####= Quickship - - Product pages -- MOBILE FIRST - =###+++ */
body.page.product-quickship .layout.layout .layout-flexible-content {
	article.node.node-page.view-mode-full  {
		margin: 0 0.9em 1em;
		
		.content {
			padding: 0.8em 6%;
			background-color: #FFF;
			box-shadow: 0 2px 12px -2px $shadow_A;
			
			p {
				line-height: 1.4;
				font-weight: 200;
				color: $steelBlueGray;
			}
		}
	}
	.flexible-row .l-flexible-row.row {
		h2.block-title, .block-title {
			margin-left: 1em;
		}
	}
} 

/* +++=####= Videos - Video pages -- MOBILE FIRST - Base - =###+++ */
body.node-type-videos {
	article.node-videos {
		text-align: center;

		.youtube_player {
			text-align: center;
			margin: auto;
		}
		
		iframe {
			max-height: 80vh;
		}
	}
}
.view.view-home-blocks.news .view-content {
    display: grid;
}

/* =#=#= Larger than Tiny Screens =#= */
@media (min-width: 400px) {
	
	.view.view-home-page-slider .item-list .row.locker-gallery-blk {
		.col-6 {
			.button-link-wrap {
				width: 320px;
				a {
					padding: 4px 14px 3px;
					font: {
						weight: 500;
						size: 16px;
					}
				}
			}
		}
	}
	/* ++======= Accessories pages - min 400px -- ---+++++=======++ */
	body.page .l-col.col-md-12.l-region--content {

			/* --===###===-- LOCKER OPTIONS - Accessories page - min 400px --==###==-- */	
		.block.block-views-accessories-items-block-options {
			.view.view-accessories-items.accessories-page-items {
				.view-content {
					.views-row.accessories.locker-options {
						.views-field {
							padding-left: 32px;
							padding-right: 32px;						
						}

						.views-field.views-field-field-hero-image {
							.field-content {							
								a {
									height: 255px;
									
									img {
										max-height: unset;
									}
								}
							}
						}
					}
				}
			}
		}

	}/* --===###===-- END --Accessories pages - min 400px --==###==-- */
	
	/****---** CHART pages --- min 400 **--****/
	.block.block-views-chart-items-block {
		.view.view-chart-items {
			.views-row {
				.views-field-field-subtitle {
					h1 {
						margin-bottom: 0.85em;
					}
				}
				
				.views-field.views-field-field-body-2 {
					.field-content {
						text-align: center;
					
						svg {
							max-height: 380px;
						}
						svg#clearance-dbl {
						    max-height: 552px;
						}
						svg#chart-sidesway {
							max-width: 80%;
						}
					}
				}
			}
		}
	}
}	/* =#=#= END Larger than Tiny Screens END =#= */

/***START***** media min 421px ***************/
@media (min-width: 421px) {
	/**** - chart page - unit load cap - **Only This Page** = - min 421 - *****/
	body.page-node-3326 {
		.block.block-views-chart-items-block {
			.view.view-chart-items.view-id-chart_items {
				>.view-content {			
					.views-row {
						grid-column: 1 / span 2;
						
						span.emph.lbl {
							padding: 0;
							line-height: 1.2;
							font-size: 13px;
						}
						
						/***** shelving unit images *********/
						&.chart-part---beaded-front-post-unit,
						&.chart-part---h-post-unit {
							grid-column: auto;
							padding: 4px 8px;
							margin-bottom: 0;
							margin-top: 0;
							
							.views-field-field-hero-image {
								.field-content {
									height: 280px;
									text-align: center;
									
									img {
										max-height: 100%;
										width: auto;
									}
								}
							}
							.views-field-body {
								order: 2;
								max-width: 100%;
								
								p {
									text-align: center;
								}
							}
						}
						/***** Beaded Post ** only - - - shelving unit images *********/
						&.chart-part---beaded-front-post-unit {
							margin-right: 0;
							padding-left: 10px;
						}
						/***** H-Post ** only - - - shelving unit images *********/
						&.chart-part---h-post-unit { 
							margin-left: 0px;
							padding-left: 12px;
						}
					}				
				}
			}
		}
	}	
}
/***END**------*** media min 421px *-----**END**---***/

@media (min-width: 490px) {
	.view.view-taxonomy-term .views-view-grid .views-grid-box {
		width: 33.33%;
	}
	body.page .l-col.col-md-12.l-region--content {

			/* --===###===-- LOCKER OPTIONS - Accessories page - min 490px --==###==-- */	
		.block.block-views-accessories-items-block-options {
			.view.view-accessories-items.accessories-page-items {
				.view-content {
					.views-row.accessories.locker-options {
						.views-field.views-field-field-hero-image {
							.field-content {							
								a {
									height: 380px;
									
									img {
										max: {
											height: 140%;
											width: 200%;
										}
									}
								}
								a:hover, a:active {
									img {
										max: {
											height: 150%;
										}
									}
								}
							}
						}
						.views-field.views-field-field-subtitle {
							h4 {
								margin-top: 30px;
								font: {
									family: $bodyFont;
									weight: 200;
									size: 1.6rem;
								}
							}
						}
					}
				}
			}
		}
		/* --===###===-- LOCKS - Accessories page - min 490px --==###==-- */	
		.block.block-views-accessories-items-block-locks {
			.block-content {
				.view.view-display-id-block_locks {
					.view-content {
						.views-row.accessories.locks {
							grid-template-columns: 150px auto;
							grid-template-rows: auto auto auto;
							grid-gap: 4px 14px;
							
							.views-field.views-field-field-hero-image {
								grid-row: span 3;
								
								.field-content a {
									padding: 12px;
									border: 3px solid $ice;
									background-color: #FFF;
									border-radius: 8px;
									
									img {
										position: absolute;
									}
								}
							}

							.views-field.views-field-field-subtitle {
								h4 {
									margin-top: 0;
									font: {
										family: $bodyFont;
										weight: 300;
									}
									text-transform: capitalize;
									color: $drab;
								}
							}
							.views-field.views-field-body .field-content {
								p {
									line-height: 1.2rem;
									font: {
										size: 0.85rem;
										family: $smallFont;
										weight: 500;
									}
									color: $steelBlue;
								}
							}
						}
					}
				}
			}
		}

	}/* --===###===-- END -- Accessories pages - min 490px --==###==-- */
	.node-type-options.page-node-2683 .l-flexible-row.row {
		article#node-2683 {
			min-width: 330px;
			.content.clearfix .field.field-name-body.field-type-text-with-summary.field-label-hidden {
				.field-items .field-item .l-col.col-md-4 {
					padding: 10px 0;
				}
			}
		}
	}
}/* ---#--- -END- -- @MEDIA min 490px -- --==###==-- */

/**** -- Media MAX-WIDTH 500 -- ****/
@media (max-width: 500px) {

/***** Quickship Page - max 500 - ******/
	article#node-2683 {
		.field.field-name-body {

		/***** Quickship Page - Left Column (top) max 500 - ******/
			.l-col.col-md-4 {
				padding: 10px 0;
				 
				.left-column {
					h2 	{			 
						clear: right;
						font-size: 1.6em;
						line-height: 1.3;
						margin: auto;
						padding: 1em 1.2em;
					}
					h3 {
						line-height: 1.2;
						padding: 0;
						margin: 12px 28px 6px;
						
						p {
							padding: 0.2em 0;
						}
						
					}
				}
			}
		}
	}
	.l-flexible-row.row .l-col.col-md-12.l-region--content {
		.block.block-views-products-displays-by-category-block-13 {
			.view.view-products-displays-by-category.view-id-products_displays_by_category.view-display-id-block_13.right-column {
				.views-row .field-content .tileBox {
					.qsHero {
						position: relative;
						height: auto;
						width: auto;
						max: {
							height: 96%;
							width: 90%;
						}
						min-width: 50%;
						padding: 0 0 0 4px;
						float: none;
						align-self: center;
						overflow: hidden;
						
						a {
							position: relative;
							display: block;
							height: 100%;
							width: 100%;
							max-height: 100%;
							max-width: 100%;
							
							img {
								display: block;
								position: relative;
								max-height: 100px;
								width: auto;
								min-width: auto;
							}
						}
					}
				}
			}
		}
	}

} /********** END max 500  - - - *****/

				
/**** -- Media MAX-WIDTH 516 -- ****/
@media (max-width: 516px) {
	body.page.page-node-3314 {
		.l-flexible-row.row {
			.l-col.col-md-12.l-region--content {		
				.block.block-views-chart-items-block .view.view-chart-items {
					.view-content {
						.views-row {
							.views-field.views-field-field-subtitle {
								height: auto;
								
								h1 {
									font-size: 2em;
									text-align: center;
								}
							}
						}
					}
				}
			}
		}
	}
}/* --=------##------ END -- @MEDIA MAX 516 -- --==###==-- */


/****##### -+++Begin+++- Media MIN-WIDTH 540 -+++begin+++- #####****/
@media (min-width: 540px) {
	
	/****##### -+ Components Finder +- ### min 540 ##****/
	.view-display-id-component_filter.components.components-finder {
		.view-content {
			.views-view-grid .views-grid-box {
				grid-template-columns: 136px auto;
			
			}
		}
	}
	/****##### -+ Unit Cap Load - Chart Page +- ### min 540 ##****/
	body.page-node-3326 {
		.block.block-views-chart-items-block {
			.view.view-chart-items.view-id-chart_items > .view-content {
				.views-row {
					
					&.Chart---Unit-Load-Capacity {
						.views-field-field-subtitle {
							h1 {
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}
	}
	
} /*-----END----*** -- Media MIN-WIDTH 540 -- ***----END----***/

/**** -- Media MAX-WIDTH 560 -- ****/
@media (max-width: 560px) {
	body.page.page-node-3314 {
		.l-flexible-row.row {
			.l-col.col-md-12.l-region--content {
				padding: 0;
				
				article.node-page.view-mode-full {
					.chart-content {
						padding: 0.3em;
					}
				}
			}
		}
	}
	
	/***** components finder --*-- ---max 560--- *****/
	body.page {
		.view-display-id-component_filter.components.components-finder {
			
			.view-filters {	
				form.views-exposed-form .views-exposed-form {
					.views-exposed-widget > label {
						margin: 0.7em 1em 0.6em;
					}
					.views-widget .bef-checkboxes {
						.form-item.form-type-bef-checkbox {
							flex: 3 1 33%;
							
							label.option {
								padding: 12px 16px;
							}
						}
					}
				}
			}
			
			.view-content {	
				.views-view-grid {
					.views-grid-box.odd {
						margin-left: -15px;
						margin-right: -15px;
						padding-left: 20px;
						padding-right: 20px;
					}
				}
			}
		}
	}
}/* --=------##------ END -- @MEDIA MAX 560 -- --==###==-- */
				
						
/**** -- Media - min:550 -- ****/
@media (min-width: 550px) {
	
	/***** Quickship Page - min-width 550 - ******/
	.node-type-options.page-node-2683 .l-flexible-row.row {
		
		/***** Quickship Page - Right Column - product tiles - min-width 550 - ******/
		.block.block-views-products-displays-by-category-block-13 {
			.block-content .view.view-products-displays-by-category.view-id-products_displays_by_category.view-display-id-block_13.right-column {
				.views-row {
					.field-content {
						.tileBox {
							grid: {
								template: {
									columns: auto auto;
									rows: auto auto auto;
								}
								gap: 0 9px;
							}
							height: auto;
							
							.qsHero {
								max: {
									height: 140px;
									width: 90%;
								}
								min-width: 50%;
								padding: 0 10px 0 0;
								grid-row: 1 / 3;
								
								a {
									position: relative;
									display: block;
									height: inherit;
									max-height: inherit;
									overflow: hidden;
									
									img {
										max-height: 100%;
										max-width: 100%;
									}
								}
							}
							.qsrow.row {
								margin-right: 0;
								margin-left: 0;
								min-height: 120px;
								flex-direction: column;
								
							}
							.qsLink {
								grid-column: 2;
								max-width: 100%;	
									
								a {
									margin-left: auto;
									padding-right: 13px;
									color: $linkBlue;
									border-color: $ice;
								}
								a:after {
									margin-top: 7px;
									border-color: $linkBlue;
								}
								a:hover {
									color: $ice;
								}
								a:hover::after {
									border-color: $ice;
								}
								a:active  {
									color: #fff;
									transition: 0s;
								}
								a:active::after {
									border-color: #fff;
								}
							}
						}
					}
				}
				.views-row.views-row-5.last .field-content {
					.tileBox {
						height: auto;
						
						.qsHero {
							padding-top: 15px;
							padding-right: 0px;
							margin-left: -4px;
							min-width: 100px;
						}
						.qsrow.row {
							h2.qsTitle {
								.qsName {
									margin-left: -3px;
									letter-spacing: .2px;
								}
							}
									
							.qsDesc { 
								padding-top: 0;
							}
						}
					}
				}
			}
		}
	}
	/******* capacity ratings - Continued - UNDER main table -*** CHART pages ***min 550+****/
	.page-node-3314 {
		.block.block-views-chart-items-block {
			margin-top: 1.2em;
			
			.view.view-chart-items {
				.view-content {
					align-items: stretch;
					flex-wrap: wrap;
					flex-direction: row;
					column-gap: 0.8em;
					row-gap: 1em;
					
					.views-row {
						flex: 1 2 35%;
						padding: 1em;
						margin: 0;
						
						.views-field.views-field-field-subtitle {
							height: 50px;
							
							h1 {
								margin: 0;
								font-size: 1.5em;
							}
						}
						
						.views-field.views-field-field-body-2 {
							.field-content {
								.bottom-note {									
									p {
										font-size: 0.9em;
									}
								}
							}
						}
						
						&.Capacity-Ratings.views-row-2 {
							flex: 3 1 45%;
						}
					}
				}
			}
		}
	}
 
}/* --=--END---##------ END -- @MEDIA min 550px -- --==###==-- */

/**** -- Media - min:561 -- ****/
@media (min-width: 561px) {
	.view-display-id-component_filter.components.components-finder {
		
		.view-filters {
			.views-exposed-form {
				.views-exposed-widget {
					padding: 0;
				}
			}
		}
		
		.view-content {
			.views-view-grid .views-grid-box {
				padding: {
					left: 1.4em;
					right: 1.4em;
				}
			}
		}
	}				
}

/**** -BEGIN- Media min 660 -- ****/
@media (min-width: 660px) {
	.view.view-taxonomy-term .views-view-grid .views-grid-box {
		width: 25%;
	}
	.layout .flexible-row .row {
		.block-title, .view-header h3 {
			font-family: $headingFont;
			font-weight: 200;
			color: $drab;
			text-transform: uppercase;
			text-align:left;
		}

		.view-header h3.block-title {
			padding-left: 5%;
			margin-top: 32px;
		}
		.view-header h3 a  {
			display: inline-block;
		}
	/* ---- Shelving Systems Block  - - -   660px -----*/
		.view-home-page-slider.home-shelving-system .view-content .item-list {
			.field-content .row.shelvingSystems-block {
				grid-template-columns: 50% 50%;
				grid-template-rows: 260px;

				.col-6:nth-child(1) {
					position: relative;

					img {
						@include centerImg;
						display: block;
						width: auto;
						max-width: unset;
						height: 100%;
					}
				}
				.col-6:nth-child(2) {
					display: grid;
					position: relative;
					grid-template-areas:'line1'
										'line2'
										'buttonLnk'
										'line3'
										'line4'
										'line5';
					padding-top: 14px;

					.text-line-1 {
						grid-area: line1;
						padding-bottom: 12px;
					}
					.text-line-2 {
						grid-area: line2;
						font-size: 32px;
						line-height:1;
					}
					.text-line-3 {
						grid-area: line3;
					}
					.text-line-4 {
						grid-area: line4;
					}
					.text-line-5 {
						grid-area: line5;
					}
					.button-link-wrap {
						grid-area: buttonLnk;
						padding: 15px;

						a {
							font-size: 16px;
							font-weight: 500;
							padding: 3px 30px 1px;
						}
						a::after {
							width: 8px;
							height: 8px;

						}
					}
				}
			}
		}
		/* ---- Lockers Gallery Block - - 660px -----*/
		.view.view-home-page-slider  {
			margin-bottom: 0px;

			.item-list {
				padding: 6px 0;

				.row.locker-gallery-blk {
					position: relative;
					overflow: hidden;
					margin: 0;
					grid: 	{
						template: {
							columns: 40% auto;
							rows: auto;
						}
					}
					align-items: center;
					background-color: $onyxLte;
					height: 280px;

					.col-6.txtBlk {
						justify-content: center;
						align-content: center;
						
						.text-line-1 {
							font-size: 32px;
						}
						.text-line-2 {
							font-size: 16px;
							line-height: 1.15;
							color: $sage;
							font-weight: 100;
						}
						.text-line-3 {
							font-size: 14px;
							padding: 5px;
						}
						.button-link-wrap {
							padding: 15px;

							a {
								padding: 8px 40px;
								line-height: 1;
								font-weight: 400;
								border-color: #374045;
							}
							a::after {
								margin-top: -1px;
								border-width: 1px 1px 0 0;
							}
						}
					}
					.col-6.imgBlk {
						position: relative;
						overflow: hidden;

						img {
							@include centerImg;
							display: block;
							width: auto;
							max-width: unset;
						}
					}
				}
			}
		}
	}
	/* ############## locker gallery - page - ###### 660 ####### */

	article.node-gallery .field-name-field-gallery-images.field-type-image {
		.field-items {
			grid: {
				template-columns: 1fr 1fr 1fr 1fr;
				gap: 8px;
			}
		}
	} /* ##--- END ---## locker gallery ##### END #### 660 #### */
	
	/* ++======= Accessories pages - min660 -- ---+++++=======++ */
	body.page .l-col.col-md-12.l-region--content {

	/* ##### Accessories NAVIGATION - min660 ###### */
		.block.accessories-nav {
			.block-content {
				ul.menu-tree.menu {
					grid: {
						gap: 6px 0;
						template-columns: 1fr 1fr 1fr;
					}
					
					li.leaf {
						text-align: center;
						
						a {
							padding: 7px 7% 4px;
							background-color: $sage;
							color: $steelBlue;
							font: {
								size: 1rem;
								weight: 600;
							}
							border: {
								color: $steelBluegreen;
							}
						}
						a.active-trail.active {
							padding: 7px 7% 4px;
						}
						a:active, a:hover {
							background-color: $drab;
							color: $sage;
						}
						a.active-trail.active:hover,
						a.active-trail.active:active {	
							color: $steelGray;
						}
					}
					li.first.leaf.acc-main {
						text-align: left;
						grid-column: span 3;
						
						a::before {
							border-width: 2px 0 0 2px;
							left: 3.5%;
							transition: 350ms ease-out;
							transform: rotate(-45deg) translate(0px, 11.5px);
						}

						a:active::before, a:hover::before {
							left: 3%;
						}
					}
				}
			}
		} /* --===###===-- END --Accessories Nav - min660 --==###==-- */		

			/* --===###===-- LOCKER OPTIONS - Accessories page - min660 --==###==-- */	
		.block.block-views-accessories-items-block-options {
			.view.view-accessories-items.accessories-page-items {
				.view-content {
					.views-row.accessories.locker-options {
						display: grid;
						grid: {
							template-columns: 30% auto;
							template-rows: auto auto;
							gap: 0.4rem 1.2rem;
						}
						margin-bottom: 0;
						background-color: #ffffffbf;
						width: 100%;
						padding: 1rem;
						
						.views-field {
							padding-left: 0;
							padding-right: 0;
						}
						
						.views-field.views-field-field-hero-image {
								grid-row: span 2;
								width: 100%;
								height: 100%;
								padding: 4px;
							
							.field-content {
								height: inherit;
								width: inherit;
								
								a {
									height: 100%;
									min-height: 160px;
									padding: 12px;
									transition: 350ms ease;
								
									img {
										position: absolute;
										height: auto;
										max: { 
											height: 136%;
											width: 200%;
										}
										min: {
											width: 100%;
											height: 100%;
										}
										
									}
								}
								a:hover, a:active {
									transition: 100ms, box-shadow 250ms ease;
									
									img {
										max: {
											height: 150%;
											width: 200%;
										}
										min: {
											width: unset;
											height: unset;
										}
										transition: 220ms ease;
									}
								}
							}
						}
						.views-field.views-field-field-subtitle h4 {
							margin-top: 1rem;
							line-height: 1.6rem;
							font: {
								family: $bodyFont;
								weight: 200;
								size: 1.425rem;
							}
						}
						.views-field.views-field-body .field-content {
							p {
								padding-right: 20px;
							}
						}
					}
				}
			}
		}

	}/* --===###===-- END --Accessories (section) pages - min660 --==###==-- */
	
	/* +++=####= Quickship - - Product pages -- min660 - =###+++ */
	body.page.product-quickship .layout.layout .layout-flexible-content {
		.container-fluid.region_12 > .l-flexible-row.row {
			margin: 0 15px;
			
			article.node.node-page {
				margin: 0 0.9em 1em;
				
				.content {
					margin: 0.2em 0.4em 0.8em;
					padding: 0.8em 1.2em;
					box-shadow: 0 2px 12px -2px $shadow_A;
					border-radius: 8px;
					
					p {
						line-height: 1.4;
						font-weight: 300;
						color: $steelBlueGray;
					}
				}
			}
		}
	}

	
	body.page.page-node-3228 .l-col.col-md-12.l-region--content {
	/* +++++###++ START --Acc LandingPage - Main Hero Image - min660 ++==###==++ */	
		.block.block-views-accessories-items-block-mainhero {
			text-align: center;
			
			.block-content .view-accessories-items .view-content {
				.views-row.mainpage {
					.views-field-field-hero-image .field-content {
						margin-top: 28px;
					}
				}
			}
		}/* --===##===-- END --Acc LandingPage - Main Hero Image - min660 --==##==-- */	
		
		
		/* +++++####++ START --Acc LandingPage - Tile Links - min660 ++==####==++ */
		.block.block-views-accessories-items-block-1 .block-content .view.acc-main {
			.view-content {
				grid-template-columns: 1fr 1fr 1fr;
				
				.views-row.mainpage {
					.views-field.views-field-title {
						.field-content.acc_tile {
							a {
								grid: {
									gap: 2px;
									template-columns: auto;
								}
								text: {
									align: center;
									decoration: none;
								}
								
								.acc_img {
									height: 200px;
								}
							}
						}
						.field-content.acc_tile.acc_tile1 {
							a .acc_img {
								img {
									top: 40%;
									min-width: 110%;
								}
							}
						}
					}
				}
			}
		}
	}
	
	/* ++======= support - VIDEOS - video page ---+++++==- min660 -=====++ */
	body.page.node-type-videos .layout .flexible-row .row {
		article.node-videos {
			background-color: #FFF;
			padding: 32px 2px 18px;
			border-radius: 8px;
			box-shadow: 2px 2px 24px -12px $shadow_A;
		}
	}
	
	/***==========* CHART pages  ---===--  *-*min-660*-* ***========*/
		/****** growth charts ****min*660*****/
	article#node-3309 {
		.field.field-name-body {
			p {
				margin: 0.5em 1.5em 0;
			}
		}
	}
	.block.block-views-chart-items-block {
		.view.view-chart-items {
			.views-row {
				margin: 24px 0;
				border-radius: 6px;
				
				.views-field.views-field-field-subtitle {
					h1 {
						margin-top: 6px;
						font: {
							family: $subFont;
							weight: 800;
						}
						line-height: 1.1;
					}
				}
				.views-field.views-field-body {
					p {
						margin: 0.4em auto 1em;
						line-height: 1.4;
					}
				}
				.views-field.views-field-field-body-2 .field-content {
					svg {
						max-height: 420px;
					}
				}
				&.views-row-1 ,
				&.views-row-2 {
					.views-field.views-field-field-body-2 {
						.field-content {
							svg {    
								width: 100%;
							}
						}
					}
				}
			}
		}
	}
	/******* capacity ratings **********/
	article.node-page.view-mode-full {
		.chart-content {	
			background-color: #FFF;
			padding: 0.5em 1.5em;
			border-radius: 6px;
			box-shadow: 4px 8px 20px -8px $shadow_A;
			
			>p {
				margin: {
					left: 2.5em;
					right: 2.5em;
				}
				color: $drab;
			}
			.table-container {
				width: 100%;
				overflow-x: auto;
				
				table.cap-rate.tbl-chart {
					th {
						font: {
							family: $headingFont;
						}
					}
				}
			}
			
		}
	}

	/****##### -+ Components Finder +- ### min 660 ##****/
	.view-display-id-component_filter.components.components-finder {
		.view-content {
			.views-view-grid .views-grid-box {
				grid: {
					template-columns: 30% auto;
					column-gap: 1.4em;
				}
				padding: 28px 1.8em 14px;
				
				.views-field-field-hero-image {
					width: auto;
				}
				
				.views-field.views-field-body {
					p {
						font-size: 16px;
					}
				}
			}
		}
	}

}/* --###--#-- END -- @MEDIA min660 ----###--#---- */

/**** -++begin++- @media min 720 -- min720 ****/
@media (min-width: 720px) {
	
	/*********** ## Components Finder ###### - min720 *********/
	.view-display-id-component_filter.components.components-finder {
		.view-content {
			.views-view-grid {
				margin-left: 1.5%;
				margin-right: 1.5%;
				max-width: 728px;
				grid-column-gap: 3.5%;				
			
				.views-grid-box {
					padding: 18px 1.15em 14px;
					grid: {
						template: {
							columns: auto;
							rows: 136px auto 3fr;
						}
						column-gap: 0;
					}				
					align-items: start;
					
					.views-field-field-hero-image {
						border-radius: 3px;
						
						.field-content a {
							display: block;
							height: 100%;
							width: 100%;
							border: 2px solid #fff;
							transition: 250ms;
							
							&:hover,
							&:active {
								border-color: $linkBlue;
							}
						}
					}
					
					h2.views-field-field-subtitle {
						margin-top: 0.15em;
					}
					
					.views-field.views-field-body {
						grid-column: 1;
					}
				}
			}
		}
	}
}/**** ---end--- min 720 -- ****/

/** -++ begin ++- ** -- Media min 768 -- min768 ---****/
@media (min-width: 768px) {
	.block-title, .view-header h3.block-title {
		font-size: 28px;
	}

	.view.view-taxonomy-term {
		max-width: 1440px;
		margin: auto;
		
		.views-view-grid .views-grid-box {
			width: 25%;
		}
	}

	.view-name-taxonomy_term {
		.no-container.hero-block {
			.l-col.col-md-12.l-region--hero-block {
				.block.block-views-child-category-block {
					width: 100%;
					overflow: hidden;
					grid-column: span 3;
				}
			}
		}
	}
	/* logged in - layout fixes -- */
	.logged-in {
		.view-name-taxonomy_term {
			.no-container.hero-block {
				.l-col.col-md-12.l-region--hero-block {
					.block.block-system-tabs {
						grid-column: span 3;
						width: 100%;
					}
				}
			}
		}
	}
	.list-cat-header h3  {
        font-size: 26px;
        margin-bottom: 0;
    }
	.product-landing-list.no-slide {
		li {
			width: 20%;
		}
		li.empty-placeholder {
			display: flex;
		}
	}

	.breadcrumb .item:last-child .crumb-title {
		font-size: 30px;
		display: block;
	}
	
	/* ++++++==|||=== SHELVING SYSTEMS - (reg-CONTENT)-- iPad1 - 768px ++-- ===|||==+++  */
	.block.block-views-home-page-slider-block-1 .block-content .view-home-page-slider.home-shelving-system {
		.view-content .item-list .field-content {
			.row.shelvingSystems-block {
				grid-template: {
					columns: auto 380px;
					rows: 280px;
				}
				.col-6 {
					.text-line-1 {
						font-size: 22px;
					}
					.text-line-2 {
						font-size: 50px;
					}
				}
				.col-6:nth-child(1) {
					img {
						min-width: 100%;
						min-height: 120%;
						height: 120%;
						width: auto;
						max-width: unset;
						max-height: unset;
					}
				}
			}
		}
	}
/* ++++++++++==|||=== Lockers Gallery - 768px - iPad1 ++-- =  */
	.layout .flexible-row .row {  
		.view.view-home-page-slider  {
			margin-bottom: 0px;

			.item-list {
				padding: 6px 0;
				background-color: $drab;

				.row.locker-gallery-blk {
					display: grid;
					margin: 0;
					grid-template-columns: 40% auto;
					align-items: center;
					background-color: $onyxLte;
					height: 400px;
					position: relative;
					overflow: hidden;
		
					.text-line-1 {
						font-size: 32px;
					}
					.text-line-2 {
						font-size: 24px;
					}
					.text-line-3 {
						font-size: 20px;
					}
				}
			}
		}
	}
	/* ++======= Accessories pages - min768 - -- ---+++++=======++ */
	body.page .l-col.col-md-12.l-region--content {

	/* ##### Accessories NAVIGATION - min768 - ###### */
		.block.accessories-nav {
			.block-content {
				ul.menu-tree.menu {
					grid: {
						gap: 2px;
						template-columns: 1fr 1fr 1fr 1fr;
					}
					
					li.leaf {
						text-align: center;
						
						a {
							font: {
								size: .825rem;
							}
						}
						a.active-trail.active:hover, a.active-trail.active:active {
							color: $steelBluegreen;
						}
					}
					li.first.leaf.acc-main {
						text-align: center;
						grid-column: span 1;
						
						a::before {
							left: 3.5%;
							transform: rotate(-45deg) translate(0px, 8.5px);
						}

						a:active::before, a:hover::before {
							left: 3%;
						}
					}
				}
			}
		}
	}	/* --===###===-- END --Accessories ITEMS PAGES (Base) Nav - min768 - --==###==-- */	
	/* ++======= Accessories LANDINGpage NAV (overrides) - min768 - -- ---+++++=======++ */
	body.page.page-node-3228 .l-col.col-md-12.l-region--content {
		.block.accessories-nav .block-content ul.menu-tree.menu {
			grid-template-columns: 1fr 1fr 1fr;
		}
	} /* --===###===-- END --Accessories Nav - min768 - --==###==-- */		
	
	body.page .l-col.col-md-12.l-region--content {	

			/* --===###===-- LOCKER OPTIONS - Accessories page - min768 - --==###==-- */	
		.block.block-views-accessories-items-block-options {
			.view.view-accessories-items.accessories-page-items {
				margin: auto;
				
				.view-content {
					.views-row.accessories.locker-options {
						grid: {
							template-columns: 180px auto;
							template-rows: auto auto;
							gap: 0.4rem 1.3rem;
						}
						box-shadow: 1px 2px 16px -10px #6e7b83a3;
						padding: 1rem 2rem;
						margin-bottom: 0;
						
						.views-field {
							padding-left: 0;
							padding-right: 0;
						}
						
						.views-field.views-field-field-hero-image {
							.field-content {
								a {
									min-height: 165px;
									box-shadow: none;
									background-color: transparent;
									border: none;
									border-radius: 4px;
									
									img {
										width: auto;
										height: auto;
										max-height: 120%;
										max-width: 160%;
									}
								}
								a:hover, a:active {
									box-shadow: none;
									transition: 100ms, box-shadow 350ms ease-out;
									
									img {
										max: {
											height: 100%;
											width: 110%;
										}
										transition: 240ms ease;
									}
								}
							}
						}
						.views-field.views-field-field-subtitle {
							align-self: center;
							margin-top: 0.225rem;
							
							h4 {
								margin-top: 0;
								font-size: 1.265rem;
							}
						}
							
						.views-field.views-field-body .field-content {
							p {
								margin-top: 0.5rem;
							}
						}
					}
				}
			}
		}
		.block.block-views-accessories-items-block-benches {
			.block-content {
				.view-accessories-items .view-content {
					grid-template-columns: 1fr 1fr;

					.views-row.benches.benches-and-pedestals {
					    grid-column: span 2;
						
						.views-field.views-field-field-hero-image {
							width: 50%;
						}
					}
				}
			}
		}
	}/* --===###===-- END --Accessories pages - min768 --==###==-- */
	
	/* ++======= support - VIDEOS - video page ---+++++==- min768 -=====++ */
	body.page.node-type-videos .layout .flexible-row .row {
		>.l-col.col-md-12.l-region--content {
			padding: 0 6%;
		}
	}
	/************ QuickShip Landing ****** min768 *********/
	.node-type-options article#node-2683 {
	    float: none;
		flex: 0 0 32%;
	}
	
	/****---** CHART pages --- min 768 **--****/
	.block.block-views-chart-items-block {
		.view.view-chart-items {
			.view-content {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				column-gap: 1.1em;
				
				.views-row {
					margin: 16px 0;
					flex: 0 0 100%;
					
					.views-field.views-field-body {
						font-size: 16px;
					}
					
					.views-field.views-field-field-body-2 {				
						.field-content.chart.unit-growth {
							display: flex;
							align-items: flex-end;
							justify-content: center;
							height: 100%;
							
							svg#clearance-dbl {
								max-height: 570px;
								height: 460px;
							}
							svg#clearance-sngle {
								height: 324px;
							}
						}
					}
					
					&.views-row-3 {
						display: flex;
						flex: 3 2 56%;
						flex: {
							direction: column;
							wrap: nowrap;
						}
						.views-field.views-field-field-body-2 {
							height: 100%;
						}
					}
					&.views-row-4 {
						flex: 2 3 38%;
					}
				}
			}
		}
	}
	/******* capacity ratings - Continued - UNDER main table -*** CHART pages ***+ min 768 +****/
	.page-node-3314 {
		.block.block-views-chart-items-block {
			margin-top: 1.2em;
			
			.view.view-chart-items {
				.view-content {
					display: flex;
					align-items: stretch;
					flex-wrap: wrap;
					flex-direction: row;
					column-gap: 1.2em;
					row-gap: 1em;
					margin-bottom: 2.4em;
					
					.views-row {
						flex: 1 2 36%;
						
						.views-field.views-field-field-subtitle {
							height: 48px;
							
							h1 {
								font-size: 1.9em;
							}
						}
						
					
						.views-field.views-field-field-body-2 {
							.field-content {
								
								&.chart.unit-growth {
									justify-content: flex-end;
								}
								svg {
									max-height: 260px;
									min-height: 140px;
								}
								.bottom-note {
									position: absolute;
									bottom: -28px;
									
									p {
										font-size: 1em;
										line-height: normal;
										color: $steelBlue;
										margin: 0.6em auto;
										width: 80%;
									}
								}
							}
						}
						
						&.Capacity-Ratings.views-row-2 {
							flex: 3 1 45%;
							
							.views-field.views-field-field-subtitle {
								margin-right: 25%;
							}
						}
					}
				}
			}
		}
	}
	
	/*********** ## Components Finder ###### - min768 *********/
	.view-display-id-component_filter.components.components-finder {
		.view-filters {
			form.views-exposed-form .views-exposed-form {
				max-width: 740px;
				margin: auto;
				
				.views-widget .bef-checkboxes {
					gap: 0.6em 1em;
				}
			}
		}	
		.view-content {
			.views-view-grid {
				margin-left: auto;
				margin-right: auto;
				grid-column-gap: 3.8%;
			}
		}
	}
	
	/*** chart page - Unit Load Cap +++ min 768 +++ *****/
	body.page-node-3326 {
		.block.block-views-chart-items-block {
			.view.view-chart-items.view-id-chart_items > .view-content {
				grid-template-columns: 30% auto auto 30%;
				
				.views-row {
					grid-column: 1 / span 4;
					
					.views-field.views-field-field-body-2 {
						.field-content.chart.unit-growth {
						    display: flex;
							align-items: center;
							justify-content: center;
							height: 100%;
							flex-direction: column;
						}
					}
					&.Chart---Unit-Load-Capacity {					
						
						.views-field-field-subtitle h1 {
							margin-bottom: 18px;
						}
						.views-field.views-field-body {
							max-width: fit-content;
							flex: 1 3 38%;
							font-size: 14px;
							padding-top: 5%;
							margin-left: 2%;
						}
						.views-field-field-body-2 {
						    flex: 1 3 60%;
							
							.chart {
								h4 {
								    font-weight: 300;
								}
								svg#legend-items {
									max-height: 69px;
								}
							}
						}
					}
					
					/*** Shelving Unit Images - +++===+++ Unit Load Cap +++ min 768 +++ *****/
					&.chart-part---h-post-unit,
					&.chart-part---beaded-front-post-unit {
						grid-column: auto;

						.views-field-field-hero-image {
							margin: auto;
						}
						
					}
					&.chart-part---h-post-unit {
						grid-column: 4;
					}
					
					/*** - Sway Braces ** Heading ** Label - +++===+++ Unit Load Cap +++ min 768 +++ *****/
					&.Chart-Item---typical-sway-brace-install {
						padding: {
							left: 0;
							right: 0;
							bottom: 2em;
						}
						grid: {
							column: 2 / span 2;
							row: 2;
						}
						align-content: flex-end;
						
						.views-field.views-field-body {
							max-width: 100%;
							text-align: center;
							
							div.title-box {
								h2 {
								    margin-bottom: 16px;
									font-size: 38px;
									
									&::before,
									&::after {
									    content: "";
										display: block;
										position: relative;
										margin: {
											left: auto;
											right: auto;
										}
										width: var(--prcnt);
										height: 6px;
										background-color: $steelBluegreen;
										border-radius: 100px;
									}
									&::before {
										margin-bottom: 3px;
									}
									&::after {
										margin-top: 3px;
									}
								}
								h4 {
									margin-top: 2em;
									color: #bbbbbb;
									text: {
										align: center;		
										shadow: 1px 1px 0px #fff;
										wrap: balance;
									}
									line-height: 1.15;
									transition: 350ms ease;
									cursor: default;
									
									&:hover {
										color: $steelBlue;
										text-shadow: 2px 2px 1px #fff;
									}
								}
							}	
						}			
					}
					/*** Sway Braces SVGs - +++===+++ Unit Load Cap +++ min 768 +++ *****/
					&.Chart---Side-Sway-Braces,
					&.Chart---Back-Sway-Braces {
						grid-column: span 2;
						align-items: flex-start;
						align-content: flex-start;
						
						.views-field-field-subtitle {
							height: 62px;
							
							h1 {
								margin-left: 7px;
								font-size: 32px;
								text-align: left;
								max-width: 75%;
							}
						}
						.views-field.views-field-field-body-2 {
							.chart {
								.svg-container {
									height: 260px;
									width: 100%;
									
									svg {
										height: 100%;
									}
								}
								.chart-caption {
									ul li {
										text-wrap: balance;
									}
								}
							}
						}
					}
					/*** Side Sway Braces - ONLY - +++===+++ Unit Load Cap +++ min 768 +++ *****/
					&.Chart---Side-Sway-Braces {
						.views-field.views-field-field-body-2 {
							.chart .svg-container {
								svg#chart-sidesway {
									max-width: 100%;
								}
							}
						}
					}
				}
			}
		}
	}
	/****--general pages - policy pages --********##### min 768 #####***********/
	body.node-type-page {
		.side-nav-container {
			margin: 12px 32px;	
			
			nav.policy-nav {
				ul.top-level-parent {
					padding-top: 12px;

				}
			}

		}
	}
	
}/* --==----------==-- END - @MEDIA min768 --==-------==-- */

/****+++++*&** === ****++++ MIN 890 ++++**+++++ === ++++&*****&++++++****/
@media (min-width: 890px) {
/*** chart page - Unit Load Cap +++ min 890 +++ *****/
	body.page-node-3326 {
		.block.block-views-chart-items-block {
			.view.view-chart-items.view-id-chart_items > .view-content {
				grid-template-columns: 27% auto auto 27%;
				
				.views-row {

					&.Chart---Unit-Load-Capacity {					
						.views-field.views-field-body {
							flex: 1 5 36%;
							padding-top: 6%;
							margin-left: 3%;
						}
						.views-field-field-body-2 {
						    flex: 1 3 60%;
							
							.chart {
								h4 {
								    font: {
										size: 22px;
										weight: 300;
									}
									color: $rust; 
									text-shadow: 0px 0px 1px $steelBluegreen;
								}
								svg#legend-items {
									max-height: 69px;
								}
							}
						}
					}
					
					/*** Shelving Unit Images - +++===+++ Unit Load Cap +++ min 890 +++ *****/
					&.chart-part---h-post-unit,
					&.chart-part---beaded-front-post-unit {
						grid-column: auto;
						

						.views-field-field-hero-image {
							.field-content {
								height: 324px;
							}
						}
						
					}
					&.chart-part---h-post-unit {
						grid-column: 4;
					}
					
					/*** - Sway Braces ** Heading ** Label - +++===+++ Unit Load Cap +++ min 890 +++ *****/
					&.Chart-Item---typical-sway-brace-install {
						padding: {
							left: 0;
							right: 0;
							bottom: 2em;
						}
						grid: {
							column: 2 / span 2;
							row: 2;
						}
						align-content: flex-end;
						
						.views-field.views-field-body {
							max-width: 100%;
							text-align: center;
							
							div.title-box {
							    width: 88%;
								margin: auto;
								
								h2 {
								    margin-bottom: 21%;
									font-size: 38px;
								}
							}	
						}			
					}
					/*** Sway Braces SVGs - +++===+++ Unit Load Cap +++ min 890 +++ *****/
					&.Chart---Side-Sway-Braces,
					&.Chart---Back-Sway-Braces {
						
						.views-field-field-subtitle {
							height: 62px;
							
							h1 {
								margin-left: 12px;
								font-size: 36px;
								max-width: 260px;
							}
						}
						.views-field.views-field-field-body-2 {
							.chart {
								.svg-container {
									height: 312px;
								}
								.chart-caption {
									p {
										font-size: 25px;
										text-indent: 12px;
									}
									ul li {
										font-size: 16px;
										line-height: 1.3;
										text-indent: initial;
										color: $onyx;
									}
								}
							}
						}
					}
					/*** Bottom Notes - +++===+++ Unit Load Cap +++ min 890 +++ *****/
					&.chart-part---cap-load-bottom-note {
						.views-field.views-field-body {
							.bottom-note-container {
								ol {
									margin-top: 8px;
									
									li {
									    line-height: 1.4;
									}										
								}
							}
						}
					}
				}
			}
		}
	}
	/******** post cap chart - - - TABLE Styles ##### ****/
	article.node-page.view-mode-full {
		.chart-content {
			.table-container {
				table.tbl-chart tr {
					th {
						background-color: $steelBluegreen;
						border-right-color: $steelBluegreen;
						padding: 10px 12px;
						font-size: 12px;
						color: #fff;
					}
					td {
						text-align: center;
						font: {
							size: 12px;
							family: $chartFont;
							weight: 600;
						}
						white-space: nowrap;
						padding: 13px 10px 10px;
						border-bottom-color: $sage;
						color: $drab;
						
						&.table-lable {
							padding: 9px 0 2px;
							font: {
								size: 26px;
								family: $subFont;
								weight: 800;
							}
							border-width: 12px 0 0;
							
						}
						&.table-txty {
							padding: 0 0 9px;
							border-width: 0;
							color: $drab;
							font: {
								size: 14px;
								family: $smallFont;
							}
						}
						
						&.sct-lbl {
							padding: 11px 16px;
							font: {
								size: 20px;
								family: $smallFont;
								weight: 700;
							}
							color: $steelBlue;
							background-color: $iceGray;
							
						}
					}
				}
			}
		}
	}
}

/****+++++++++ MIN 1024 ++++**+++++ === +++++++++++****/
@media (min-width: 1024px) {
	.layout .flexible-row .row {
		.block-title, .view-header  h3.block-title {
			margin: 24px 0 4px 0;
			text-align: left;
			font-size: 32px;
			font-family: $headingFont;
			font-weight: 200;
			color: $drab;
			text-transform: uppercase;
		}
		.view.view-taxonomy-term, 
		.view-child-category {
			.views-view-grid .views-grid-box {
				width: 20%;
			}
			.product-landing-list {
				.views-field-name a {
					font-size: 14px;
				}
				.no-slide li{
					width: 20%;
				}
			}
		}
	}
	body.page .layout .flexible-row .row {
	/* ++======= Accessories page - Benches & Pedestals Block -- 1024 -- iPad2 -- ---+++++=======++ */
		.block.block-views-accessories-items-block-benches {
			.block-content {
				.view-accessories-items.accessories-page-items .view-content {
					display: grid;
					grid: {
						gap: 38px 20px;
						template: {
							columns: 1fr 1fr 1fr;
							rows: auto;
						}
					}
					.views-row {
						.views-field.views-field-field-hero-image {
							grid-area: hero;
							width: 100%;
							height: 300px;
							
							.field-content {
								height: 100%;
							}
						}
						.views-field.views-field-field-subtitle {
							grid-area: sub;
							
							h4.field-content.title.subtitle {
								font: {
									family: $bodyFont;
									weight: 500;
								}
								color: $steelBlue;
							}
						}
						.views-field.views-field-body {
							grid-area: body;
							
							.field-content { 
								p {
									font: {
										family: $specialFont;
										weight: 400;
									}
									color: #707070;
									line-height: 1.3;
									padding: 0 12px;
								}
								table {
									margin-left: 12px;
									max-width: 97%;
									background-color: #fff;
									border: 2px solid #dee2ea;
									
									tr td {
										border: {
											width: 2px 0;
											color: #dee2ea;
										}
									}
								}
							}
						}
						.views-field.views-field-field-document {
							grid-area: doc;
							align-self: end;
							padding: 0 12px;
						}
					}
					
					.views-row.benches.benches-and-pedestals{
						display: grid;
						grid: {
							column: span 3;
							column-gap: 12px;
							template: {
								columns: 1fr 1fr 1fr 1fr 1fr;
								areas: 	'hero hero sub sub sub'
										'hero hero body body body'
										'hero hero body body body'
										'hero hero doc doc doc';
							}
							row-gap: 6px;
						}
						.views-field.views-field-field-subtitle {
							padding: 0 12px 2px;
							
							h4.field-content.title.subtitle {
								font: {
									family: $bodyFont;
									weight: 500;
									size: 32px;
								}
								color: $steelBlue;
							}
						}
						
						.views-field.views-field-field-hero-image {
							height: 300px;
							width: 100%;
							
							.field-content a {
								height: 100%;
								width: 100%;
								overflow: hidden;
								border-radius: 12px;
								box-shadow: 5px 7px 12px -5px #43646e40;
								
								img {
								    max-width: 90%;
									max-height: 120%;
									width: auto;
									top: 55%;
								}
							}
						}
					}
					.views-row.accessories.pedestals {
						display: grid;
						grid: {
							template: {
								areas:
									'hero'
									'sub'
									'body'
									'doc';
								columns: 1fr;
							}
						}
						height: 100%;
						width: 100%;
						margin: auto;
						
						.views-field.views-field-field-hero-image {
							height: 220px;
							width: 100%;
							
							.field-content {
								a {
									height: 100%;
									width: 100%;
									overflow: hidden;
									border-radius: 12px;
									box-shadow: 5px 7px 12px -5px #43646e40;
									
									img {
										max: {
											height: 90%;
											width: 90%;
										}
										width: auto;
										position: relative;
										display: block;
									}
								}
							}
						}
						.views-field.views-field-field-subtitle {
							padding: 12px 12px 0;
							
							h4.field-content.title.subtitle {
								font: {
									family: $bodyFont;
									weight: 500;
									size: 20px;
								}
								color: $steelBlue;
							}
						
						}
					}
				}
			}
		}
		
		.block .block-content {
			.view.view-home-page-slider {
				max-width: 1440px;
				margin: auto;
				
		/* ++======= Lockers Gallery Block -- 1024 -- iPad2 -- ---+++++=======++ */
				.item-list {
					.row.locker-gallery-blk {
						display: grid;
						grid-template-columns: 34% auto;
						margin: 0;
						align-items: center;
						background-color: $onyxLte;
						height: 400px;

						.col-6 {

							img {
								width: 100%;
								display: block;
							}
						}
						.col-6.txtBlk {
							height:400px;
							padding: 20px 50px;
							
							.text-line-1 {
								grid-area: line1;
								font-size: 34px;
								margin-top: 0;
							}
							.text-line-2 {
								grid-area: line2;
								font-size: 20px;
								height:auto;
								line-height: 1.4;
							}
							.text-line-3 {
								grid-area: line3;
								font: {
									size: 16px;
									style: italic;
								}
								color: $ice;
							}

						}
					}
				}
			}
			
		/*### - Shelving Systems - ## - 1024 - ####*/
			.view-home-page-slider.home-shelving-system {
				.view-content .item-list .field-content {
					.row.shelvingSystems-block {
						grid-template: {
							columns: auto 516px;
							rows: 400px;
						}
						.col-6:nth-child(1)  {
							img {
								min-width: 100%;
								min-height: 130%;
								height: 130%;
								width: auto;
							}
						}
						.col-6:nth-child(2) {
							padding: 26px 30px;

							p {
								font-size: 19px;
							}
							.text-line-1 p {
								font: {
									family: $headingFont;
									size: 19px;
									weight: 500;
								}
							}
							p.text-line-2 {
								font: {
									size: 48px;
									weight: 900;
								}
							}
							.button-link-wrap a {
								padding: 6px 60px 4px;
								width: auto;
							}
						}
					}
				}
			}
		}
	}
	
	/* ############## locker gallery ###### 1024 ####### */
	article.node-gallery .field-name-field-gallery-images.field-type-image {
		.field-items {
			grid: {
				template-columns: 1fr 1fr 1fr 1fr 1fr;
				gap: 24px;
			}
		}
	} /* ###### END ###### locker gallery ##### END #### 1024 #### */
	
	body.page .l-col.col-md-12.l-region--content {
		.block.block-views-accessories-items-block-locks {
			.block-content {
				.view.view-display-id-block_locks {
					.view-content {
						grid-gap: 32px 22px;
						grid-template-columns: 1fr 1fr;
					}
				}
			}
		}
	}
	
	body.page.page-node-3228 .l-col.col-md-12.l-region--content {
		.block.block-menu-menu-accessories.accessories-nav,
		article#node-3228,
		.block.block-views-accessories-items-block-1 {
			max-width: 1260px;
			margin-left: auto;
			margin-right: auto;
		}
	}
	
	/* ++======= support - VIDEOS - video page ---+++++==- min1024 -=====++ */
	body.page.node-type-videos .layout .flexible-row .row {
		>.l-col.col-md-12.l-region--content {
			padding: 0 12%;
		}
	}
	/** #############  general content pages --- min1024 --- ###########**/
	.block.block-views-page-block-items-general-block {
		.node--updated-date {
			.views-row.first {
				text-align: right;
			}
		}
	}
	/* ++=+++++== CHART pages - cap ratings TABLE ---+++++==- min1024 -=====++ */
	article.node-page.view-mode-full {
		.chart-content {
			.table-container {
				table.cap-rate.tbl-chart {
					tr {
						td {
						    font-size: 14px;							
						}
						&:nth-child(1) {
							td {
								&:nth-child(4),
								&:nth-child(5),
								&:nth-child(6),
								&:nth-child(7) {
									font-size: 13px;
									padding: {
										left: 12px;
										right: 12px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	/* ++=**cont**== CHART pages - cap ratings ---+++++==- min1024 -=====++ */
	.page-node-3314 {
		.block.block-views-chart-items-block .view.view-chart-items {
			.view-content {
				.views-row {
					.views-field.views-field-field-subtitle {
						margin-right: 36%;
						height: 32px;
						padding-left: 1.2em;					
					}

					&.Capacity-Ratings.views-row-2 {					
						.views-field.views-field-field-subtitle {
							margin-right: 56%;
						}
					}
					.views-field.views-field-field-body-2 .field-content {
						.bottom-note {
							bottom: -12px;
							
							p {
								font-size: 1em;
								width: auto;
							}
						}
					}
				}
			}
		}
	}
	
	/*********** ## Components Finder ###### - min1024 *********/
	.view-display-id-component_filter.components.components-finder {
		margin-left: -15px;
		margin-right: -15px;
		padding: 0 15px 13px;
		background: linear-gradient(#d4d5d7,#dee2ea, #e6e8ed, #dee2ea, #ececec, transparent);

		.view-filters {
			form.views-exposed-form {
			    min-height: 150px;
				margin-bottom: 28px;
				
				.views-exposed-form {
					max-width: 88%;
					
					.views-exposed-widget {
						display: block;
					}
					
					.views-widget .bef-checkboxes {
						.form-item.form-type-bef-checkbox {
						    flex: 2 3 18%;
						}
					}
				}
			}
		}
		.view-content {
			.views-view-grid {
				max-width: 98%;
				grid-column-gap: 3.8%;
			}
		}
	}
	/*** @media ** min1024 && max1199 ****** ## Components Finder ###### - *********/
	@media (max-width: 1199px) {
		.view-display-id-component_filter.components.components-finder .view-content .views-view-grid {
			.views-grid-box.odd {
				background-color: #6E8CA0;
				color: #F0F3F4;
			}
		}
	}

	/*### cookie Consent ### min 1024 ###*/
	div.block-views-page-block-items-general-block-2 .consent-cookie-msg {
		width: auto;
		max-width: 86%;
		
		// Cookie Grid Layout
		.view-content .views-row.views-row-1 {
			display: grid;
			grid-template-columns: auto 70% 1fr;
			grid-template-areas:
				'img ttl ttl'
				'img txt acc'
				'img txt out';
			width: 100%;
			margin: auto;
			background-color: #FFF;
			justify-content: center;
			align-items: center;

			// Cookie Title
			.views-field.views-field-field-text-line-2 {
				padding-right: 12px;
				text-align: left;
				
				h3.cookieMsg-title {
					color: #3f444a;
					margin-bottom: 0;
					padding-bottom: 3px;
					text-align: left;
					font-weight: 600;
					font-size: 24px;
					border-bottom: 4px solid #ececec;
					line-height: 1;
				}
			}

			// Cookie Image
			.views-field-field-image {
				.field-content.cookie-img {
					position: relative;
					padding: 9px 14px 2px 3px;
					height: 100%;
					border-radius: 0;
					top: 0;
					left: 0;
					background-color: transparent;
					border: none;
				}
			}

			// cookie message text
			.views-field-field-text-line-1 {
				p {
					color: $drab;
					line-height: 1.25;
					margin: auto;
					text-shadow: 1px 1px 1px #FFF;
					a {
						color: $sage;
					
						&:hover {
							color: $linkBlue;
							text-decoration: underline;
						}
						&:active {
							color: $steelBluegreen;
							text-decoration: underline;
						}
					}
				}
			}
			
			// Cookie Buttons - min 1024
			.views-field-field-button-link,
			.views-field-field-text-line-3 {
				margin: 0;
				padding: 0;

				.c-button {
					padding: 4px 2px;
					a {
						margin-left: 2px;
						margin-right: 8px;
					}
				}
			}
		}
	}
	/*### END ### cookie Consent ### min 1024 ###*/

}/* --==----------==-- END - @MEDIA min1024 --==-------==-- */

/******+++++++-==-- Begin - @MEDIA min1096 --==-++++++++******/
@media (min-width: 1096px) {
	article.node-page.view-mode-full {
		.chart-content {
			.table-container {
				table.cap-rate.tbl-chart {
					tr {
						td {
						    font-size: 16px;							
						}
						&:nth-child(1) {
							td {
								&:nth-child(4),
								&:nth-child(5),
								&:nth-child(6),
								&:nth-child(7) {
									font-size: 14px;
									padding: {
										left: 22px;
										right: 22px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	
	/*********** ## Components Finder ###### - min1096 *********/
	.view-display-id-component_filter.components.components-finder {
		.view-content {
			.views-view-grid {
				max-width: 96%;
				grid-gap: 2.3vw;
			}
		}
	}
}/* --==----------==-- END - @MEDIA min1096 --==-------==-- */

/******+++++++-==-- Begin - @MEDIA min1130 --==-++++++++******/
@media (min-width: 1130px) {
	.node-type-options.page-node-2683 {
		.l-flexible-row.row {
			article#node-2683 {
				padding: 0.3em 2em;
			}
		}
	}
}

/******+++++++-==-- Begin - @MEDIA min1200 +++++**+++++ min 1200 --==-++++++++******/
@media (min-width: 1200px) {

	/*********** ## Components Finder ###### - min1200 *********/
	.view-display-id-component_filter.components.components-finder {
		.view-content {
			.views-view-grid {
				max-width: 98%;
				grid-gap: 1.5vw;
			}
		}
	}
}

/*==*** &&-- Media Query &&-- ***==*/
@media (min-width: 1310px) {
	.layout .flexible-row .container-fluid.region_12 .row {
		.l-col.col-md-12.l-region--content {
			max-width: 1440px;
			margin: auto;
		}
	}
	.breadcrumb .item:last-child .crumb-title {
		font-size: 50px;
	}
	
	.view-taxonomy-term ,
	.view-child-category {
		.views-view-grid .views-grid-box {
			width: 20%;
		}
	}
}
@media (min-width: 1440px) {
	.layout .flexible-row .hero-block .row {
		.l-col.col-md-12.l-region--hero-block {
			max-width: 1440px;
			margin: auto;
		}
	}
	body.page .l-col.col-md-12.l-region--content {
	
	/*#### -- Accessories Pages - Locker Options - 1440 -- ######*/
		.block.block-views-accessories-items-block-options {
			.view.view-accessories-items.accessories-page-items {
				.view-content.photoswipe-gallery {
					display: grid;
					grid-template-columns: 1fr 1fr;
					grid-column-gap: 32px;
				}
			}
		}
	}
} /*#### -END- @MEDIA min-width:1440 --###*/
@media (max-width: 910px) {
	.product-landing-list {
		.swiper-button-prev, .swiper-button-next  {
  			width: 30px;
			height: 30px;
		}

		.slick-arrow {
			width: 40px;
			height: 40px;
			z-index: 99;
		}
	}

	.view-child-category {
		.views-field-name .cat-title-wrap.has-parent img {
			display: none;
		}
		.views-view-grid {
			white-space: nowrap;
			overflow: auto;
			display: block;
			
			.views-grid-box {
				display: inline-block;
				width: 40vw;
				margin: 0 5px;
			}
		}
		h3 {
			text-align: center;
			font-weight: 500;
			width: 100%;
		}
	}
	.view-taxonomy-term .views-view-grid {
		padding: 0 10px;
		width: 100%;
		margin-left: 0;
	}


	.view-name-taxonomy_term .col-md-12 {
		padding: 0;
	}
}
@media (max-width: 767px) {

	.product-landing-list {
		display: none;
		
		.no-slide li {
			width: 33%;
		}
	}
	.product-landing-list.swiper2  {
		display: block;
	}
	.view-child-category .mobile-slide {
		display: none !important;
	}
	.mobile-slide {
		display: block !important;
		position: relative;
		width: calc(100% + 3.75em);
		margin-left: -1.875em !important;
		overflow: hidden;
	}
	/**** MEDIA ---- Min 421 max 767 - *****/
	@media (min-width: 421px) {
		/**** - chart page - unit load cap - **Only This Page** = - Min 421 max 767 - *****/
		body.page-node-3326 {
			.block.block-views-chart-items-block {
				.view.view-chart-items.view-id-chart_items {
					>.view-content {
						.views-row {
		
							/***** shelving unit images *********/
							&.chart-part---beaded-front-post-unit,
							&.chart-part---h-post-unit {
								box-shadow: none;
							}
						}
					}
				}
			}
		}
	}
}/*****END**** max 767 ***********/

@media (max-width: 720px) {

	.view-child-category .views-view-grid {
		padding: 0 15px;
		margin-left: 0;
		width: 100%;
	}
}

@media (max-width: 659px) {
	article.node-videos {
		background-color: #FFF;
		padding: 32px 12px 18px;
		margin-left: -15px;
		margin-right: -15px;
	}
}

@media (max-width: 543px) {
	.product-landing-list .no-slide li {
		width: 50%;
	}
	article.node-videos iframe {
		max-height: 80vh;
		height: 52vw;
	}
	
	/***** SKU & ADDON - page display ******/
	body.node-type-sku,
	body.node-type-addon {

		article.view-mode-full.node-sku,
		article.view-mode-full.node-addon {
			padding: 1,2;
			margin-top: 1em;
			border-radius: 12px;
			box-shadow: 5px 5px 17px -8px #29364099;
			
			.content.clearfix {
				.field-name-field-subtitle.field-type-text {
					.field-items .field-item {
						font-size: 1.6em;
						line-height: 1;
					}
				}
				.group-left-column.field-group-fieldset {
					margin-top: 0.7em;
				}
			}
			.field-label-inline {
				
				.field-items .field-item {
					font-size: 1.1em;
					line-height: 1.2;
				}
			}
			.field-name-field-table-name.field-type-text {
				font-size: 1.2em;
	
			}
		}
	}
	    
} /*********** end max 543 *************/

@media (max-width: 420px) {

	/****=====** CHART pages *-*MAX--420*-* **=====****/
	article#node-3309 {
		.field.field-name-body {
			p {
				font-size:0.9em;
			}
		}
	}
	.block.block-views-chart-items-block {
		.view.view-chart-items {
			.views-row {
				
				.views-field.views-field-field-subtitle {
					h1 {
						font-size: 2.2em;
					}
				}
				.views-field.views-field-body,
				.views-field-field-body-2 {	
					p, 
					li {
						font-size: 0.85em;
						line-height: 1.25;
					}
				}
				.svg-container {
					height: 250px;
					text-align: center;
					
					svg {
						height: 100%;
					}
					svg#chart-sidesway {
						padding: 4px;
					}
				}
			}
		}
	}
}

/****++++++++++=** @MEDIA *-*MAX--399*-* **+++++++++****/
@media (max-width: 399px) {
	/****=====** CHART pages *-*MAX--399*-* **=====****/
	.block.block-views-chart-items-block {
		.view.view-chart-items {
			.views-row {
				
				.views-field.views-field-field-subtitle {
					h1 {
						font-size: 1.8em;
						margin-bottom: 0.4em;
					}
				}
				.views-field.views-field-body,
				.views-field-field-body-2 {	
					h4 {
						text-align: center;
					}
					p, 
					li {
						font-size: 0.85em;
						line-height: 1.25;
					}
				}
				.svg-container {
					height: 250px;
					text-align: center;
					
					svg {
						height: 100%;
					}
					svg#chart-sidesway {
						padding: 4px;
					}
				}
			}
		}
	}
}

/****++++++++++=** @MEDIA *-*MAX--380*-* **+++++++++****/
@media (max-width: 380px) {
	/****=====** CHART pages *-*MAX--380*-* **=====****/
	.block.block-views-chart-items-block {
		.view.view-chart-items.view-id-chart_items {
			.view-content {
				.views-row {
					.views-field {
						.field-content {
							.chart-caption {
								ul {
									padding-left: 10px;
									
									li {
									    text-indent: -6px;
									}
								}
							}
						}
					}
					
					.svg-container {
						height: 250px;
						text-align: center;
					
						svg {
							height: 100%;
						}
						svg#chart-sidesway {
							padding: 4px;
						}
					}
				}
			}
		}
	}
} /****---- --END-- - == ** @MEDIA *-*MAX--380*-* *****-------***-****/


